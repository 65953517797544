import { endpoints } from "../../../constants/url.endpoint";
import { manageAdvicesActions } from "./manageAdvices.reducer";

const {
  resetManageAdvicesData,
  advicesListingSuccess,
  addAdviceSuccess,
  deleteAdviceListSuccess,
  resetDeleteAdviceListSuccess,
  resetAdviceDetail,
  updateAdviceSuccess,
  viewAdviceSuccess,
  contentIdsListingSuccess,
  updateContentIdSuccess,
  viewContentIdSuccess,
} = manageAdvicesActions;

export const getAllAdvices =
  (axiosInstance, apiData, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.adviceListing,
        method: "POST",
        data: apiData,
        params,
      })
        .then((res) => {
          dispatch(advicesListingSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetManageAdvicesData());
          reject(err);
        });
    });
  };

export const deleteAdvicesApi =
  (axiosInstance, apiData,params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deleteAdviceNestingList,
        method: "POST",
        data: apiData,
        params
      })
        .then((res) => {
          // dispatch(deleteAdviceListSuccess(res?.data?.deleteStatus));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetDeleteAdviceListSuccess());
          reject(err);
        });
    });
  };

export const addAdvices =
  (axiosInstance, adviceDetails) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addAdvices,
        method: "POST",
        data: adviceDetails,
      })
        .then((response) => {
          dispatch(addAdviceSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetAdviceDetail());
          reject(error);
        });
    });
  };

export const updateAdvicesActivity =
  (axiosInstance, adviceDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.updateAdvices}/${params.id}`,
        method: "POST",
        data: adviceDetails,
      })
        .then((response) => {
          dispatch(updateAdviceSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetAdviceDetail());
          reject(error);
        });
    });
  };

export const viewAdvice = (axiosInstance, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: `${endpoints.viewAdviceByID}/${params.id}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(viewAdviceSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetAdviceDetail());
        reject(error);
      });
  });
};

export const getContentIdsListing =
  (axiosInstance, payload, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.contentIdListing}`,
        method: "POST",
        data: payload,
        params,
      })
        .then((response) => {
          dispatch(contentIdsListingSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetAdviceDetail());
          reject(error);
        });
    });
  };
export const viewContentIdData =
  (axiosInstance, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.viewContentIdData}/${params.id}`,
        method: "GET",
      })
        .then((response) => {
          dispatch(viewContentIdSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetAdviceDetail());
          reject(error);
        });
    });
  };

export const editContentIdData =
  (axiosInstance, params, payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.editContentIdData}/${params.id}`,
        method: "POST",
        data: payload,
      })
        .then((response) => {
          dispatch(editContentIdData(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetAdviceDetail());
          reject(error);
        });
    });
  };

export const addContentIdData =
  (axiosInstance, payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.addContentIdData}`,
        method: "POST",
        data: payload,
      })
        .then((response) => {
          dispatch(updateContentIdSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetAdviceDetail());
          reject(error);
        });
    });
  };
