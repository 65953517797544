import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Heading from '../../../../shared/components/heading/heading';
import { SETTINGS_MANAGEMENT } from '../settings.constant';
import style from './privacyPolicy.module.scss';
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import Editor from '../../../../shared/components/editor/editor';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { languageState } from '../../../../redux/AurLaVieAdmin/languages/getLanguages.selector';
import { updateContent, viewContent } from '../../../../redux/AurLaVieAdmin/manageContent/manageContent.actions';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
    const location = useLocation()
    const isFengShuiSetting = location.pathname === SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI

    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [edit, setEdit] = useState(false);
    const [content, setContent] = useState("");
    const [toastList, setToastList] = useState([]);
    const [contentId, setContentId] = useState(null);
    const languageData = useSelector(languageState);

    // useEffect(() => {
    //     handleViewContent();
    // }, [axiosInstance]);

    // useEffect(() => {
    //     if (languageData.languageId) {
    //         handleViewContent();
    //     }
    // }, [languageData]);

    useEffect(() => {
        if (languageData?.languageId) {
          handleViewContent()
        }
    }, [axiosInstance, languageData, isFengShuiSetting])

    const handleViewContent = () => {
        setEdit(false);
        const appId = localStorage.getItem("appId")
        const languageId = languageData?.languageId;
        const pageName = "privacy";
        // const pageName = isFengShuiSetting ? "privacy" : "privacy";
        const params = {
            appId,
            languageId,
            pageName,
        };
        dispatch(viewContent(axiosInstance, params))
            .then((response) => {
                setContentId(response?._id);
                setContent(response?.description);
            })
            .catch((error) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Error fetching content ",
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
    };

    const onEdit = () => {
        setEdit(true);
    };

    const handleEdit = () => {
        const payload = {
            description: content,
        };
        const params = {
            id: contentId,
        };
        dispatch(updateContent(axiosInstance, payload, params))
            .then((response) => {
                setEdit(false);
                setToastList([
                    {
                        id: 0,
                        // title: "Content Updated",
                        title: response.message,
                        icon: "success",
                    },
                ]);
                // setTimeout(() => {
                //     handleViewContent();
                // }, 1000);
            })
            .catch((error) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Error fetching content ",
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
    };

    const handleContent = (event) => {
        setContent(event);
    };

    return (
        <section className={style.container}>
            <div className={style.wrapper}>
                <section className={style.title_filters}>
                    <Heading title={SETTINGS_MANAGEMENT.PRIVACY_POLICY.HEADING.TITLE} description={SETTINGS_MANAGEMENT.PRIVACY_POLICY.HEADING.DESCRIPTION} fontSize={'30px'}/>
                    <div className={style.filters}>
                        {edit ? (
                            <Button text={SETTINGS_MANAGEMENT.PRIVACY_POLICY.BUTTONS.SAVE_TITLE} buttonType={`${buttonStyle.button_primary}`} onClick={handleEdit} />
                        ) : (
                            <Button icon={"/icons/edit.svg"} text={SETTINGS_MANAGEMENT.PRIVACY_POLICY.BUTTONS.TITLE} onClick={onEdit} />
                        )}
                    </div>
                </section>
                <section className={style.content}>
                    {edit ? <h3 className={style.heading}>Edit Content</h3> : <h3 className={style.heading}>Content</h3>}
                    {edit ? <Editor content={content} setContent={handleContent} /> : <div className={style.container} dangerouslySetInnerHTML={{ __html: content }}></div>
                    // <Editor content={content} readyOnly={true} />
                }
                </section>
            </div>
        </section>
    )
}

export default PrivacyPolicy;