import { useEffect, useState } from "react";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import Input from "../../../../shared/components/inputFields/input";
import { NOTIFICATION_MANAGEMENT } from "../notification.constant";
import { addNotifications } from "../../../../redux/AurLaVieAdmin/manageNotifications/manageNotification.actions";
import style from "./addNotification.module.scss";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import Switch from "../../../../components/Global/ReactSwitch/Switch.component";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

const AddNotification = () => {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [toastList, setToastList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const languageData = useSelector(languageState)

  const location = useLocation()
  const isFengShuiSetting = location.pathname === NOTIFICATION_MANAGEMENT.URL_PATH.ADD_NOTIFICATION
  const appId = localStorage.getItem("appId")
  const languageId = languageData?.languageId

  const inputFields = [
    {
      type: "text",
      name: "title",
      label: "Notification Title",
      required: true,
      width: "100%",
    },

    {
      type: "textarea",
      name: "description",
      label: "Content",
      required: true,
      width: "100%",
    },
    {
      type: "toggle",
      name: "switch",
      label: "",
      width: "100%",
    },
    {
      type: "mui-date-picker",
      name: "scheduleDate",
      label: "",
      width: "100%",
    },
  ];

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    const hasEmptyRequiredFields = inputFields
      .filter(field => field.required)
      .some(field => !formData[field.name]);
    setIsSubmitDisabled(hasEmptyRequiredFields);
  }, [inputFields, formData]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    formData["languageId"] = languageId;
    formData["appId"] = appId;
    formData["scheduleStatus"] = toggle;

    formData.scheduleDate &&
      (formData.scheduleDate = moment(formData.scheduleDate).format());

    dispatch(addNotifications(axiosInstance, formData))
      .then((response) => {
        setToastList([
          {
            id: 0,
            // title: "Notification Added",
            title: response.message,
            icon: "success",
          },
        ]);

        setTimeout(() => {
          isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI) : navigate("/aurlavie/notifications")
        }, 1000);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            // title: "Error adding notification",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'title') {
      if (value === '') {
        setFormErrors({ ...formErrors, title: 'Title is required' });
      } else {
        setFormErrors({ ...formErrors, [name]: '' });
      }
    } else if (name === 'description') {
      if (value === '') {
        setFormErrors({ ...formErrors, description: 'Description is required' });
      } else {
        setFormErrors({ ...formErrors, [name]: '' });
      }
    } else {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={NOTIFICATION_MANAGEMENT.ADD_NOTIFICATION.BACK.TITLE}
            description={
              NOTIFICATION_MANAGEMENT.ADD_NOTIFICATION.BACK.DESCRIPTION
            }
          />
        </div>
        <div className={style.form_container}>
          <form onSubmit={handleSubmit}>
            <div className={style.form_container}>
              {inputFields.map((inputField, index) =>
                inputField.type === "toggle" ? (
                  <div className={style.label_container}>
                    <label>
                      {
                        NOTIFICATION_MANAGEMENT.ADD_NOTIFICATION.SWITCH_LABEL
                          .TITLE
                      }
                    </label>
                    <Switch handleToggle={handleToggle} isOn={toggle} />
                  </div>
                ) : (
                  <div className={style.input_field} key={index} style={{ width: "100%" }}>
                    <Input
                      disablePast={true}
                      isDisabled={inputField.name === 'scheduleDate' ? !toggle  : false}
                      type={inputField.type}
                      name={inputField.name}
                      label={inputField.label}
                      value={formData[inputField.name]}
                      placeholder={inputField.placeholder}
                      options={inputField.options}
                      required={inputField.required}
                      onChange={(value) =>
                        handleInputChange(inputField.name, value)
                      }
                      handleInputBlur={handleInputBlur}
                      views={["day", "hours", "minutes"]}
                      openTo={"day"}
                    />
                    <div className={style.error}>{formErrors[inputField.name]}</div>
                  </div>
                )
              )}
              <div className={style.action_button}>
                <Button
                  text={NOTIFICATION_MANAGEMENT.ADD_NOTIFICATION.BUTTONS.TITLE}
                  disabled={isSubmitDisabled} buttonType={isSubmitDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default AddNotification;
