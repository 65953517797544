import { useEffect, useState } from "react";
import style from './addEditUserRole.module.scss';
import Button from "../../../../../shared/components/button/button";
import buttonStyle from '../../../../../shared/components/button/button.module.scss';
import { TextAlign } from "../../../../../shared/globalConstants/constants";
import Heading from "../../../../../shared/components/heading/heading";
import { USER_ROLES } from "../../subAdminUserRolesContants";
import Input from "../../../../../shared/components/inputFields/input";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { addUserRole, editUserRole } from "../../../../../redux/AurLaVieAdmin/userRoles/userRoles.action";
import { useDispatch } from "react-redux";
import { axiosInstance } from "../../../../../constants/axiosInstance";

const AddEditUserRole = (props) => {

    const { isEdit, permissions, handleClose, selectedOptions, closed } = props;

    const [toastList, setToastList] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const inputFields = [
        {
            type: "text",
            name: "roleName",
            placeholder: 'Add Role Name',
            label: 'Role Name',
            width: '50%'
        },
        {
            type: "multi-select-dropdown",
            name: "accessProvided",
            options: permissions,
            placeholder: 'Multi Selection',
            label: 'Access Provided',
            width: '50%'
        }
    ];

    useEffect(() => {
        if (!selectedOptions) {
            setFormData({})
            setPermissionIds([]);
            setPermissionIds([])
        } else {
            setFormData({ ...formData, roleName: selectedOptions.roleName })
            setPermissionIds(selectedOptions.permissions)
        }
    }, [selectedOptions])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!Object.keys(formData).length) {
            return setToastList([
                {
                    id: 0,
                    // title: "Missing",
                    title: "Please provide a role name",
                    icon: "error",
                },
            ]);
        }
        else if (formData.roleName === "") {
            return setToastList([
                {
                    id: 0,
                    // title: "Missing",
                    title: "Please provide a role name",
                    icon: "error",
                },
            ]);
        }
        else if (!permissionIds.length) {
            return setToastList([
                {
                    id: 0,
                    // title: "Missing",
                    title: "Please provide the permissions",
                    icon: "error",
                },
            ]);
        }
        if (!isEdit) {
            const payload = {
                roleName: formData.roleName,
                permissionIds: permissionIds.map(element => (element.value)),
                created_by: localStorage.getItem('userId')
            }
            dispatch(addUserRole(axiosInstance, payload)).then(res => {
                setToastList([
                    {
                        id: 0,
                        // title: "Added Successfully",
                        title: res.message,
                        icon: "success",
                    },
                ]);

                setTimeout(() => {
                    setFormData({})
                    setPermissionIds([])
                    handleClose()
                }, 1000)

            }).catch(err => {
                console.log(err)
                if (err) {
                    return setToastList([
                        {
                            id: 0,
                            // title: "Error",
                            title: err.response.data.message,
                            icon: "error",
                        },
                    ]);
                }
            })
        } else {
            const payload = {
                roleName: formData.roleName,
                permissionIds: permissionIds.map(element => (element.value)),
                modified_by: localStorage.getItem('userId')
            }
            const params = {
                id: selectedOptions.id
            }
            dispatch(editUserRole(axiosInstance, payload, params)).then(res => {
                setToastList([
                    {
                        id: 0,
                        // title: "Updated Successfully",
                        title: res.message,
                        icon: "success",
                    },
                ]);
                setTimeout(() => {
                    setFormData({})
                    setPermissionIds([])
                    handleClose()
                }, 1000)

            }).catch(err => {
                console.log(err)
                if (err) {
                    return setToastList([
                        {
                            id: 0,
                            // title: "Error",
                            title: err.response.data.message,
                            icon: "error",
                        },
                    ]);
                }
            })
        }
    }

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    }

    const handleOptionSelection = (data) => {
        setPermissionIds(data)
    }

    return <div className={style.container}>
        <div className={style.wrapper}>
            <Heading title={isEdit ? USER_ROLES.ADD_EDIT_ROLE.HEADING.TITLE_EDIT : USER_ROLES.ADD_EDIT_ROLE.HEADING.TITLE_ADD} description={USER_ROLES.ADD_EDIT_ROLE.HEADING.DESCRIPTION} textAlign={TextAlign.CENTER} />
            <div className={style.form_container}>
                <form onSubmit={handleSubmit}>
                    <div className={style.input_container}>
                        {inputFields.map((inputField, index) => (
                            <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                <Input
                                    type={inputField.type}
                                    label={inputField.label}
                                    name={inputField.name}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}
                                    onChange={(value) => handleInputChange(inputField.name, value)}
                                    options={inputField.options}
                                    optionsSelected={handleOptionSelection}
                                    handleOptions={permissionIds}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={style.action_button}>
                        <Button type='submit' text={isEdit ? USER_ROLES.ADD_EDIT_ROLE.BUTTONS.EDIT_TITLE : USER_ROLES.BUTTONS.TITLE} buttonType={`${buttonStyle.button_primary}`} />
                    </div>
                </form>
            </div>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
        />
    </div>
}

export default AddEditUserRole;