import { useEffect, useRef, useState } from "react";
import Heading from "../../../shared/components/heading/heading";
import Table from "../../../shared/components/table/table";
import { ADVICE_MANAGEMENT } from "./advice.constant";
import style from "./adviceManagement.module.scss";
import Input from "../../../shared/components/inputFields/input";
import { AppType } from "../../../shared/globalConstants/constants";
import Button from "../../../shared/components/button/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAdvicesApi,
  getAllAdvices,
} from "../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { languageState } from "../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

import { selectUsersListingData } from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.selectors";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import moment from "moment";
import Toaster from "../../../components/Global/Toaster/Toaster.component";
import CenterModal from "../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../shared/components/delete/delete";
import EditActivityCards from "./activityCards/editActivityCards/editActivityCards";

const AdviceManagment = () => {
  const [advices, setAdvices] = useState([]);
  const [selectedApplicationType, setSelectedApplicationType] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allUsersdaa = useSelector(selectUsersListingData);
  const languageData = useSelector(languageState);
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const [payloadData, setPayloadData] = useState({});

  const currentDeleteRef = useRef(null);
  const currentAdviceRef = useRef(null);

  const [ids, setIds] = useState(null);

  const [appId, setAppId] = useState(localStorage.getItem("appId"));
  const [languageId, setLanguageId] = useState(
    localStorage.getItem("languageId")
  );

  const handleAdvices = () => {
    currentDeleteRef.current = null;
    setShowDeleteModal(false);
    const params = {
      page,
      limit: 10,
    };

    const payload = {
      appId,
      languageId,
    };
    setPayloadData(payload);
    getAdvices(axiosInstance, payload, params);
  };

  useEffect(() => {
    if (languageData.languageId) {
      const params = {
        page: 1,
        limit: 10,
      };

      const payload = {
        appId,
        languageId: languageData.languageId,
      };
      setPayloadData(payload);
      getAdvices(axiosInstance, payload, params);
    }
  }, [languageData, deleteStatus]);

  useEffect(() => {
    handleAdvices();
  }, [page,deleteStatus]);

  useEffect(() => {
    setPage(1);
    let timerId;
    const fetchResults = async () => {
      try {
        const params = {
          page: 1,
          limit: pageSize,
        };

        const payload = {
          appId,
          languageId,
          keyword: searchTerm,
        };
        setPayloadData(payload);
        getAdvices(axiosInstance, payload, params);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm, deleteStatus]);

  const getAdvices = (axiosInstance, payload, params) => {
    dispatch(getAllAdvices(axiosInstance, payload, params))
      .then((response) => {
        setPayloadData(payload);
        currentAdviceRef.current = response.data.data.listingData;
        const updatedAdvices = response?.data?.data?.listingData.map(
          (data, index) => {
            return {
              id: data._id,
              "Section Listing": data?.adviceName,
              languageId: data?.languageId,
              "Last Updated on": moment(data?.modified_at).format(
                "MMM DD YYYY"
              ),
            };
          }
        );

        setAdvices(updatedAdvices);
        setTotalItem(response?.data?.data?.meta?.total);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          return setAdvices([]);
        } else {
          setToastList([
            {
              id: 0,
              // title: "Fething Notification Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        }
      });
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  const onEdit = (data) => {
    navigate(`/aurlavie/adviceManagement/activityCards/view/${data?.id}/true`, {
      state: {
        title: data[`Card Name`],
        openModal: true,
      },
    });
  };

  const onDelete = ({ id, languageId }) => {
    setDeleteStatus(false);
    dispatch(deleteAdvicesApi(axiosInstance, { _id: id, languageId }))
      .then((response) => {
        setDeleteStatus(true);
      })
      .catch((err) => {
        setDeleteStatus(false);
      });
  };

  const onView = (event) => {
    // navigate(`/contentManagement/${event.id}`);
  };

  const handleMultiple = (event) => {
    setIds(event);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const deletemany = () => {
    if (ids.length) {
      setShowDeleteModal(true);
    }
  };

  const handleClose = (data) => {
    setShowEditActivity(false);

    const params = {
      page,
      limit: 10,
    };

    const payload = {
      appId,
      languageId,
      parentId: null,
      description: "",
    };
    setPayloadData(payload);
    getAdvices(axiosInstance, payload, params);
  };

  const selectedRow = (row) => {
    // return navigate(`/contentManagement/${row.id}`);

    const filteredAdvice = currentAdviceRef.current.filter(
      (element) => element._id === row.id
    );

    if (filteredAdvice[0].adviceType === "Child") {
      navigate(`/aurlavie/adviceManagement/activityCards/${row.id}`, {
        state: { title: filteredAdvice[0].adviceName, parentId: row.id },
      });
    }
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal isOpen={showEditActivity} onClose={handleClose}>
          <EditActivityCards
            title={ADVICE_MANAGEMENT.ADD_ACTIVITY_CARD.HEADING.TITLE}
            isClosed={isClosed}
            payload={payloadData}
            handleClose={handleClose}
            select={"Add"}
          />
        </CenterModal>
        {/* <CenterModal
                    isOpen={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                    }}
                    minWidth={"300px"}
                >
                    <Delete
                        title={ADVICE_MANAGEMENT.DELETE_NOTIFICATION.TITLE}
                        description={
                            ADVICE_MANAGEMENT.DELETE_NOTIFICATION.DESCRIPTION
                        }
                        onButtonClick={handleDeleteNotification}
                    />
                </CenterModal> */}
        <section className={style.title_filters}>
          <Heading
            title={ADVICE_MANAGEMENT.HEADING.TITLE}
            description={ADVICE_MANAGEMENT.HEADING.DESCRIPTION}
            icon="/icons/advice_blue.svg"
          />
          <div className={style.filters}>
            <Input
              type={"search"}
              placeholder={ADVICE_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              options={AppType}
              onChange={onSearch}
            />
            {/* <Button icon={"/icons/delete.svg"} onClick={deletemany} /> */}
          </div>
        </section>
        <div
          style={{
            width: "fit-content",
            marginTop: "14px",
            marginLeft: "auto",
          }}
        >
          <Button
            onClick={() => setShowEditActivity(true)}
            icon={"/icons/add.svg"}
            text={ADVICE_MANAGEMENT.ACTIVITY_CARDS.ADD_BUTTON.TITLE}
          />
        </div>
        <section className={style.listing}>
          <Table
            noDataText={"No Advices found"}
            handleMultiple={handleMultiple}
            data={advices}
            // onView={(event) => onView(event)}
            onEdit={(event) => onEdit(event)}
            onDelete={onDelete}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            selectedRow={selectedRow}
            editNotRequired={false}
            viewNotRequired={true}
            deleteNotRequired={false}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default AdviceManagment;
