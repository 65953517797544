import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../shared/components/inputFields/input";
import { REPORT_MANAGEMENT } from "../reportsManagementContants";
import style from './editContentId.module.scss';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { editReportsContentId, getReportCategories, viewReportsContentId } from "../../../../redux/AurLaVieAdmin/reports/reports.action";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { useNavigate, useParams } from "react-router-dom";
import Editor from "../../../../shared/components/editor/editor";
import AWS from 'aws-sdk';
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import { generateUUID } from "../../../../hooks/uniqueIdGenerator";
import { Status } from "../../../../shared/globalConstants/constants";

const EditContentId = () => {

    const [formData, setFormData] = useState({});
    const axiosInstance = useAxiosInstance();
    const [toastList, setToastList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [reportDetails, setReportDetails] = useState();
    const { type, id } = useParams()
    const dispatch = useDispatch();
    const [headerColors, setHeadColors] = useState([]);
    const [exampleImages, setExampleImages] = useState([]);
    const [headImages, setHeadImages] = useState();
    const [description, setDescription] = useState("");
    const [contentDetails, setContentDetails] = useState({});
    const navigate = useNavigate();
    const inputFields = [
        {
            type: "text",
            name: "reportName",
            placeholder: "Report Name",
            label: "Report Name",
            width: "100%",
        },
    ];
    const inputFields2 = [
        {
            type: "radio",
            name: "status",
            label: 'Status',
            options: Status,
            width: '50%'
        }
    ];

    useEffect(() => {
        getReportCategory(axiosInstance);
    }, [axiosInstance])

    useEffect(() => {
        const params = {
            isAdmin: true
        }
        getReport(axiosInstance, params, id);
    }, [id])

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const myBucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
        region: process.env.REACT_APP_AWS_REGION,
    });

    const uploadToS3 = async (file, name, containerIndex, subContainerIndex, select, headerImageIndex) => {
        const id = generateUUID()
        const fileKey = `content-${id}.${file?.type.split("/")[1]}`;
        const params = {
            Body: file,
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: fileKey,
        };
        try {
            dispatch(isLoading(true))
            const upload = await myBucket.upload(params).promise();
            if (upload) {
                dispatch(isLoading(false));
                if (select === "container") {
                    handleInputChange(upload.Location, subContainerIndex, containerIndex, "img")
                } else {
                    handleMainContent(upload.Location, headerImageIndex, select)
                }
            }
        } catch (error) {
            console.log(error);
            setToastList([
                {
                    id: 0,
                    // title: "Error",
                    title: "Error while uploading the resume.",
                    icon: "error",
                },
            ]);
            dispatch(isLoading(false));
        }
    };

    const getReportCategory = (axiosInstance) => {
        dispatch(getReportCategories(axiosInstance)).then((response) => {
            const updatedCategories = response.listingData.map(category => {
                return {
                    label: category?.categoryName,
                    value: category?._id
                }
            });
            setCategories(updatedCategories);
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    // title: "Error Fetching Report Categories",
                    title: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const getReport = (axiosInstance, params, reportId) => {
        dispatch(viewReportsContentId(axiosInstance, params, reportId)).then((response) => {
            setReportDetails(response?.data?.data);
            setDescription(response?.data?.data?.description);
            setHeadColors(response?.data?.data?.headColors);
            setExampleImages(response?.data?.data?.subHeadings);
            setHeadImages(response?.data?.data?.headImages);
            setContentDetails(response?.data?.data);
            setFormData({ ...response.data.data });
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    // title: "Error Fetching Report Detail",
                    title: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const updateReport = (axiosInstance, payload, params, contentId) => {
        dispatch(editReportsContentId(axiosInstance, payload, params, contentId)).then((response) => {
            setToastList([
                {
                    id: 0,
                    // title: "Update Success",
                    title: "Report Updated Successfully",
                    icon: "success",
                },
            ]);
            setTimeout(() => {
                navigate(`/fengshui/reportsManagement/viewContentId/${type}/${payload.reportContentId}`);
            }, 1500)
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    // title: "Error Updating Report Content",
                    title: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleInputChange = (value, subContainerIndex, containerIndex, select) => {
        let updatedContentDetails = JSON.parse(JSON.stringify(contentDetails));
        let updatedContainers = JSON.parse(JSON.stringify([...headerColors]));
        let updatedExampleImages = JSON.parse(JSON.stringify([...exampleImages]));
        if (select === "colorName") {
            updatedContainers[containerIndex].colorContent[subContainerIndex].colorName = value;
        } else if (select === "color") {
            updatedContainers[containerIndex].colorContent[subContainerIndex].color = value;
        } else if (select === "img") {
            updatedExampleImages[containerIndex].exampleImages[subContainerIndex].imgPath = value;
        } else if (select === "heading") {
            updatedContainers[containerIndex].heading = value;
        } else if (select === "exampleImageheading") {
            updatedExampleImages[containerIndex].heading = value;
        } else if (select === "exampleImageName") {
            updatedExampleImages[containerIndex].exampleImages[subContainerIndex].imgName = value;
        } else if (select === 'reportName') {
            updatedContentDetails.reportLabel = value.value;
            updatedContentDetails.reportName = value.label;
            setFormData({ reportContentId: { label: value.label, value: value.value } });
        }
        setHeadColors([...updatedContainers]);
        setExampleImages([...updatedExampleImages]);
        setContentDetails(updatedContentDetails);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleMainContent = (value, index, select) => {
        let updatedContainersHead = JSON.parse(JSON.stringify(contentDetails));
        if (select === "mainImage") {
            if (value === "" || value.includes("undefined")) {
                updatedContainersHead.imagePath = null;
            } else {
                updatedContainersHead.imagePath = value;
            }
        }
        if (select === "headImage") {
            if (value === "" || value.includes("undefined")) {
                updatedContainersHead.headImages.splice(index, 1, null);
            } else {
                updatedContainersHead.headImages.splice(index, 1, value);
            }
        }
        setContentDetails(updatedContainersHead)
    }

    const handleFile = (url, containerIndex, subContainerIndex) => {
        if (url === " ") {
            handleInputChange("", subContainerIndex, containerIndex, "img")
        } else if (url && url.name) {
            uploadToS3(url, url.name, containerIndex, subContainerIndex, "container")
        }
    };

    const removeContainer = (index) => {
        const updatedContainers = [...headerColors];
        updatedContainers.splice(index, 1);
        setHeadColors(updatedContainers);
    };

    const removeHeading = (index) => {
        const updatedContainers = [...exampleImages];
        updatedContainers.splice(index, 1);
        setExampleImages(updatedContainers);
    };

    const addsubContainer = (index) => {
        let updatedContainers = JSON.parse(JSON.stringify([...headerColors]))
        if (!updatedContainers[index]?.colorContent) {
            updatedContainers[index][`colorContent`] = []
        }
        updatedContainers[index]?.colorContent.push({ color: "", colorName: "" })
        setHeadColors([...updatedContainers]);
    };

    const addExampleImage = (index, data) => {
        if (data?.exampleImages?.length >= 6) return
        let updatedContainers = JSON.parse(JSON.stringify([...exampleImages]))
        if (!updatedContainers[index]?.exampleImages) {
            updatedContainers[index][`exampleImages`] = []
        }
        updatedContainers[index]?.exampleImages?.push({ imgName: "", imgPath: "" })
        setExampleImages([...updatedContainers]);
    }

    const removeSubContainer = (index, containerDetails) => {
        let updatedContainers = JSON.parse(JSON.stringify([...headerColors]));
        updatedContainers[containerDetails].colorContent.splice(index, 1);
        setHeadColors([...updatedContainers]);
    };

    const removeExampleImage = (index, containerDetails) => {
        let updatedContainers = JSON.parse(JSON.stringify([...exampleImages]));
        updatedContainers[containerDetails].exampleImages.splice(index, 1);
        setExampleImages([...updatedContainers]);
    };

    const addHeaderImage = () => {
        setHeadColors([...headerColors, {}]);
    };

    const addHeading = () => {
        setExampleImages([...exampleImages, {}]);
    }

    const handleMainImage = (url, index, imageType) => {
        if (url === " ") {
            handleMainContent("", index, imageType)
        }
        else if (url && url.name) {
            uploadToS3(url, url.name, null, null, imageType, index)
        }
    };

    const handleSave = () => {
        let updatedContentDetails = JSON.parse(JSON.stringify(contentDetails));
        updatedContentDetails['headColors'] = headerColors;
        updatedContentDetails.subHeadings = exampleImages;
        updatedContentDetails.description = description;
        updatedContentDetails.status = formData.status;
        const params = {
            isAdmin: true
        }
        updateReport(axiosInstance, updatedContentDetails, params, id);
    }

    const handleInputChange2 = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue })
    }

    return <div className={style.container}>
        <div className={style.wrapper}>
            <div className={style.back}>
                <Back title={REPORT_MANAGEMENT.EDIT_REPORT_CONTENT_ID.BACK.TITLE} description={REPORT_MANAGEMENT.EDIT_REPORT_CONTENT_ID.BACK.DESCRIPTION} />
            </div>
            <section className={style.content}>
                <div className={style.upload}>
                    <h3 className={style.heading}>Main Image</h3>
                    <ImageUpload
                        previewImage={reportDetails?.imagePath ? reportDetails?.imagePath : null}
                        id="mainImage"
                        handleFile={(url) => handleMainImage(url, null, "mainImage")}
                    />
                </div>
                <div className={style.form}>
                    <form onSubmit={handleSubmit}>
                        {inputFields.map((inputField, index) => (
                            <div
                                key={index}
                                className={style.input_field}
                                style={{ width: inputField.width }}
                            >
                                <Input
                                    type={inputField.type}
                                    label={inputField.label}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}

                                    onChange={() => {
                                        return true
                                    }
                                    }
                                />
                            </div>
                        ))}
                        {inputFields2.map((inputField, index) => (
                            <div
                                key={index}
                                className={style.input_field}
                                style={{ width: inputField.width }}
                            >
                                <Input
                                    type={inputField.type}
                                    name={inputField.name}
                                    label={inputField.label}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}
                                    options={inputField.options}
                                    required={inputField.required}
                                    onChange={(value) => handleInputChange2(inputField.name, value)}
                                    handleInputBlur={() => { }}
                                />
                            </div>
                        ))}
                        <div className={style.upload}>
                            {formData?.reportName?.toLowerCase().includes("color") || formData?.reportName?.toLowerCase().includes("colour")
                                || formData?.reportName?.toLowerCase().includes("colors") || formData?.reportName?.toLowerCase().includes("colours") ?
                                null :
                                <div className={style.headImages_container}>
                                    <h3 className={style.heading}>Head Images</h3>
                                    <div className={style.images}>
                                        {
                                            headImages?.map((headImage, index) => {
                                                return <div key={index} className={style.headImages}>
                                                    <ImageUpload height={`80px`} width={`80px`} id={index}
                                                        previewImage={headImage ? headImage : null}
                                                        handleFile={(url) => handleMainImage(url, index, "headImage")} />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>}

                            <label className={style.heading}>Head Colors</label>
                            <div className={style.headerImages}>
                                {
                                    headerColors && headerColors.length ? headerColors?.map((headerImage, index) => {
                                        return <div key={index} className={style.headerImage}>
                                            <div className={style.heading}>
                                                <Input
                                                    type="text"
                                                    name="heading"
                                                    placeholder="Heading"
                                                    label="Heading"
                                                    value={headerImage?.heading}
                                                    onChange={(value) => handleInputChange(value, null, index, "heading")}
                                                    mainWidth={'100%'}
                                                />
                                                <Button icon={"/icons/delete.svg"} onClick={() => removeContainer(index)} />
                                            </div>
                                            {
                                                headerImage?.colorContent?.map((color, innerIndex) => {
                                                    return <div key={innerIndex} className={style.colors}>
                                                        <div>
                                                            <label className={style.color_label}>Choose Color</label>
                                                            <input type="color" className={style.color_picker} value={color.color} onChange={(e) => handleInputChange(e.target.value, innerIndex, index, "color")} />
                                                        </div>
                                                        <Input
                                                            type="text"
                                                            name="colorName"
                                                            placeholder="Color Name"
                                                            label="Color Name"
                                                            value={color.colorName}
                                                            onChange={(value) => handleInputChange(value, innerIndex, index, "colorName")}
                                                            mainWidth={'100%'}
                                                        />
                                                        <Button icon={"/icons/delete.svg"} onClick={() => removeSubContainer(innerIndex, index)} />
                                                    </div>
                                                })
                                            }
                                            <div className={style.add_buttons}>
                                                <Button
                                                    text={"+ Add Colors"}
                                                    buttonType={`${buttonStyle.button_secondary}`}
                                                    marginTop={0}
                                                    onClick={() => addsubContainer(index)}
                                                />
                                            </div>
                                        </div>
                                    }) : null
                                }
                                <div className={style.add_buttons}>
                                    <Button
                                        text={"+ Add Heading"}
                                        buttonType={`${buttonStyle.button_secondary}`}
                                        marginTop={0}
                                        onClick={() => addHeaderImage()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={style.editor_container}>
                            <h3 className={style.heading}>Content</h3>
                            <Editor borderRequired={false} readyOnly={false} setContent={(data) => setDescription(data)} content={description} />
                        </div>
                        <div className={style.example_images}>
                            <div className={style.example_images_container}>
                                <h3 className={style.label}>Example Images</h3>
                                <div className={style.image_heading}>
                                    {exampleImages?.map((data, index) => {
                                        return <div key={index} className={style.image_heading_container}>
                                            <div className={style.heading}>
                                                <Input
                                                    type="text"
                                                    name="heading"
                                                    placeholder="Heading"
                                                    label="Heading"
                                                    value={data?.heading}
                                                    onChange={(value) => handleInputChange(value, null, index, "exampleImageheading")}
                                                    mainWidth={'100%'}
                                                />
                                                <Button icon={"/icons/delete.svg"} onClick={() => removeHeading(index)} />
                                            </div>
                                            {
                                                data?.exampleImages?.map((exampleImage, innerIndex) => {
                                                    return <div key={innerIndex} className={style.exampleImage}>
                                                        <ImageUpload containerIndex={index} subContainerIndex={innerIndex} id={`${index}${innerIndex}`} handleFile={(data) => handleFile(data, index, innerIndex)} previewImage={exampleImage?.imgPath ? exampleImage?.imgPath : null} height={'80px'} width={'80px'} />
                                                        <div style={{ width: '100%', marginLeft: '8px'}}>
                                                        <Input
                                                            type="text"
                                                            name="imageName"
                                                            placeholder={`Image Name ${innerIndex + 1}`}
                                                            label={`Image Name ${innerIndex + 1}`}
                                                            value={exampleImage?.imgName}
                                                            onChange={(value) => handleInputChange(value, innerIndex, index, "exampleImageName")}
                                                            mainWidth={'100%'}
                                                        />
                                                        </div>
                                                        <Button icon={"/icons/delete.svg"} onClick={() => removeExampleImage(innerIndex, index)} />
                                                    </div>
                                                })
                                            }
                                            <div className={style.add_buttons}>
                                                <Button
                                                    text={"+ Add Example Images"}
                                                    buttonType={`${buttonStyle.button_secondary}`}
                                                    marginTop={0}
                                                    onClick={() => addExampleImage(index, data)}
                                                    />
                                                </div>
                                            {/* {
                                                data?.exampleImages?.length < 6 ?
                                            <div className={style.add_buttons}>
                                                <Button
                                                    text={"+ Add Example Images"}
                                                    buttonType={`${buttonStyle.button_secondary}`}
                                                    marginTop={0}
                                                    onClick={() => addExampleImage(index, data)}
                                                />
                                            </div> : null} */}
                                            </div>
                                        })}
                                    </div>
                                    <div className={style.add_buttons}>
                                        <Button
                                            text={"+ Add Heading"}
                                            buttonType={`${buttonStyle.button_secondary}`}
                                            marginTop={0}
                                            onClick={() => addHeading()}
                                        />
                                    </div>
                            </div>
                        </div>
                        <div className={style.action_button}>
                            <Button
                                text={REPORT_MANAGEMENT.EDIT_REPORT_CONTENT_ID.BUTTONS.TITLE}
                                buttonType={`${buttonStyle.button_primary}`}
                                onClick={handleSave}
                            />
                        </div>
                    </form>
                </div>
            </section>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={3500}
        />
    </div>
}

export default EditContentId;