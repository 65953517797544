import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosInstance from '../../../../hooks/useAxiosInstance'
import { getSingleBetaUserResident } from '../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions'
import style from './userResidentDetail.module.scss'
import Back from '../../../../shared/components/backButton/backButton'
import Heading from '../../../../shared/components/heading/heading'
import useExtractSubstring from '../../../../hooks/useExtractSubstring'
import Button from '../../../../shared/components/button/button'
import moment from 'moment'
import CenterModal from '../../../../shared/components/modal/centeredModal/centeredModal'
import Delete from '../../../../shared/components/delete/delete'
import { deleteBetaUserResident } from '../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions';
import Toaster from '../../../../components/Global/Toaster/Toaster.component'
// import { Twins } from '../../../../shared/globalConstants/constants'
// import Input from '../../../../shared/components/inputFields/input'

const UserResidentDetail = () => {
    // const inputFields = [
    //     {
    //         type: "radio",
    //         name: "Twins",
    //         label: 'Twins',
    //         options: Twins,
    //         disabled: "true",
    //         width: '50%'
    //     },

    // ]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const axiosInstance = useAxiosInstance()
    const [toastList, setToastList] = useState([])
    const [detail, setDetail] = useState({})
    const [showDelete, setShowDelete] = useState(false)
    const { id } = useParams()

    useEffect(() => {
      dispatch(getSingleBetaUserResident(axiosInstance, {}, {}, id)).then((response) => {
        setDetail(response?.data?.data)
      }).catch((error) => {
        setToastList([
            {
                id: 0,
                // title: "Error Fetching User Resident",
                title: error.response.data.message,
                icon: "error",
            },
        ]);
      })
    }, [])

    const onDelete = (type) => {
        if(type === 'Cancel') {
            setShowDelete(false)
        } else if (type === 'Ok') {
            dispatch(deleteBetaUserResident(axiosInstance, {ids: [id]}, {})).then(res => {
                setShowDelete(false)
                setToastList([{
                    id: 0,
                    // title: `Resident Deleting Successful`,
                    title: res.data.message,
                    icon: "success",
                }])
                setTimeout(() => {
                    navigate(-1)
                }, 1500)
            }).catch(error => {
                setShowDelete(false)
                setToastList([{
                    id: 0,
                    // title: `Error deleting Resident`,
                    title: error.response.data.message,
                    icon: "error",
                }])
            })
        }
    }

    return (
      <div className={style.container}>
      <div className={style.wrapper}>
      <CenterModal
            isOpen={showDelete}
            onClose={() => {
                setShowDelete(false)
            }}
            minWidth={"300px"}
            >
            <Delete
                title={"Delete Resident"}
                description={"Are you sure you want to delete this Resident?"}
                onButtonClick={onDelete}
            />
        </CenterModal>
        <div className={style.back}>
            <Back title={'Resident Details'} description={'Back to view user'} />
        </div>
        <div className={style.heading}>
            <Heading title={useExtractSubstring(detail?._id)} description={''} />
            <div className={style.action_buttons}>
                {/* <Button icon={'/icons/edit.svg'} onClick={editUser} /> */}
                <Button icon={'/icons/delete.svg'} onClick={() => setShowDelete(true)} />
            </div>
        </div>
        <div className={style.details}>
            <div className={style.profile_image}>
                <img src={detail?.profilePic ? detail?.profilePic : "/icons/user_blue.svg"} alt="resident_img" />
            </div>
            <div className={style.profile_details}>
                <Heading title={'Resident Details'} description={''} fontSize={'1.4rem'} />
                <hr className={style.divider} />
                <div className={style.column}>
                    <div className={style.row}>
                        <div className={style.sub_detail}>
                            <label className={style.label}>Name</label>
                            <p className={style.value}>{detail?.name}</p>
                        </div>
                        <div className={style.sub_detail}>
                            <label className={style.label}>Birth Date with Time</label>
                            <p className={style.value}>{moment(detail?.dob).format("MM/DD/YYYY, hh:mm")}</p>
                        </div>
                        <div className={style.sub_detail}>
                            <label className={style.label}>Gender</label>
                            <p className={style.value}>{detail?.gender}</p>
                        </div>
                        <div className={style.sub_detail}>
                            <label className={style.label}>Sexual Preference</label>
                            <p className={style.value}>{detail?.sexualPreference}</p>
                        </div>
                        <div className={style.sub_detail}>
                            <label className={style.label}>Twins</label>
                            <p className={style.value}>
                              {/* {inputFields.map((inputField, index) => {
                                return (
                                  <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                  <Input
                                      type={inputField.type}
                                      name={inputField.name}
                                      label={inputField.label}
                                      value={detail?.twins}
                                      placeholder={inputField.placeholder}
                                      options={inputField.options}
                                      required={inputField.required}
                                      onChange={() => {}}
                                      handleInputBlur={() => {}}
                                  />
                             </div>
                                )
                            })} */}
                            {detail?.twins ? 'Yes' : "No"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
        />
      </div>
    )
}

export default UserResidentDetail