import Button from "../../../../../shared/components/button/button";
import Heading from "../../../../../shared/components/heading/heading";
import Input from "../../../../../shared/components/inputFields/input";
import { SETTINGS_MANAGEMENT } from "../../settings.constant";
import style from './editGetHelp.module.scss';
import buttonStyle from '../../../../../shared/components/button/button.module.scss';
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { editHelp } from "../../../../../redux/AurLaVieAdmin/getHelp/getHelp.action";
import { useDispatch } from "react-redux";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";

const EditGetHelp = (props) => {

    const { details, editSuccess, isFengShuiSetting } = props;

    const inputFields = [
        {
            type: "text",
            name: "question",
            placeholder: 'Type your question here',
            label: 'Question',
            required: true,
            width: '100%'
        },
        {
            type: "textarea",
            name: "answer",
            placeholder: 'Answer',
            label: 'Answer',
            required: true,
            width: '100%'
        },
    ];
    const [formData, setFormData] = useState({});
    const axiosInstance = useAxiosInstance();
    const [toastList, setToastList] = useState([]);
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        const hasEmptyRequiredFields = inputFields
            .filter(field => field.required)
            .some(field => !formData[field.name]);
        setIsSubmitDisabled(hasEmptyRequiredFields);
    }, [inputFields, formData]);

    useEffect(() => {
        setFormData(details);
    }, [details])

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const helpDetails = {
            id: formData.id,
            question: formData.question,
            answer: formData.answer,
        }
        const params = {
            isAdmin: true
        }
        editGetHelp(axiosInstance, helpDetails, params);
    }

    const editGetHelp = (axiosInstance, helpDetails, params) => {
        dispatch(editHelp(axiosInstance, helpDetails, params)).then((response) => {
            setToastList([
                {
                    id: 0,
                    // title: "Update Success",
                    title: "Help Updated Successfully",
                    icon: "success",
                },
            ]);
            setTimeout(() => {
                editSuccess(true);
            }, 1000)
        }).catch((err) => {
            setToastList([
                {
                    id: 0,
                    // title: "Editing Help Failed",
                    title: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'question') {
            if (value === '') {
                setFormErrors({ ...formErrors, question: 'Question is required' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'answer') {
            if (value === '') {
                setFormErrors({ ...formErrors, answer: 'Answer is required' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else {
            setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <section className={style.title_filters}>
                    <Heading title={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.EDIT_FAQ.HEADING.TITLE : SETTINGS_MANAGEMENT.GET_HELP.EDIT_GET_HELP.HEADING.TITLE} description={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.EDIT_FAQ.HEADING.DESCRIPTION : SETTINGS_MANAGEMENT.GET_HELP.EDIT_GET_HELP.HEADING.DESCRIPTION} />
                    <div className={style.filters}>
                        <Button type='submit' onClick={handleSubmit} text={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.EDIT_FAQ.BUTTONS.SAVE_TITLE : SETTINGS_MANAGEMENT.GET_HELP.EDIT_GET_HELP.BUTTONS.SAVE_TITLE} disabled={isSubmitDisabled} buttonType={isSubmitDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} />
                    </div>
                </section>
                <div className={style.form_container}>
                    <form>
                        {inputFields.map((inputField, index) => (
                            <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                <Input
                                    type={inputField.type}
                                    name={inputField.name}
                                    label={inputField.label}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}
                                    onChange={(value) => handleInputChange(inputField.name, value)}
                                    options={inputField.options}
                                    required={inputField.required}
                                    handleInputBlur={handleInputBlur}
                                />
                                <div className={style.error}>{formErrors[inputField.name]}</div>
                            </div>
                        ))}
                    </form>
                </div>
            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={3500}
            />
        </div>
    )
}

export default EditGetHelp;