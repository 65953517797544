import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetToasters } from "../../../redux/appToaster/appToaster.actions";
import { selectToasterData } from "../../../redux/appToaster/appToaster.selectors";
import { selectLoaderData } from "../../../redux/progressLoader/progressLoader.selectors";
import ProgressLoaderComponent from "../ProgressLoader/ProgressLoader.component";
import Toaster from "../Toaster/Toaster.component";
import HeaderComponent from "../header/header.component";
import SidebarComponent from "../sidebar/sidebar.component";
import "./HomeLayout.component.scss";
import style from "./homeLayout.module.scss";

const HomeComponent = ({ childrenComponent }) => {
  const [toastList, setToastList] = useState([]);
  const toasterData = useSelector(selectToasterData);
  const dispatch = useDispatch();
  const loader = useSelector(selectLoaderData);

  useEffect(() => {
    if (toasterData !== {} && toasterData) {
      setToastList([toasterData]);
      dispatch(resetToasters());
    }
  }, [dispatch, toasterData]);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.sidebar}>
          <SidebarComponent />
        </div>
        <div className={style.layout}>
          <div className={style.header}>
            <HeaderComponent />
          </div>
          <div className={style.screen}>{childrenComponent}</div>
        </div>
        <div className="homeLayout-end-border"></div>
        <Toaster
          toastList={toastList}
          position={"top-right"}
          autoDelete={true}
          autoDeleteTime={2500}
        />
      </div>
      {loader && <ProgressLoaderComponent />}
    </div>
  );
};

export default HomeComponent;
