import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import Heading from "../../../../shared/components/heading/heading";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../shared/components/inputFields/input";
import Button from "../../../../shared/components/button/button";
import { TextAlign } from "../../../../shared/globalConstants/constants";
import style from "./addBooks.module.scss";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import { getUserTypes } from "../../../../redux/AurLaVieAdmin/userType/userType.action";
import { addBook } from "../../../../redux/AurLaVieAdmin/books/books.action";
import { BOOKS_MANAGEMENT } from "../booksManagementConstants";
import AWS from "aws-sdk";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { useNavigate } from "react-router-dom";
import ToggleButton from "../../../../shared/components/toggleButton/toggleButton";
import { generateUUID } from "../../../../hooks/uniqueIdGenerator";

const AddBooks = ({ handleClose, isClosed, handleReset }) => {
  const inputFields = [
    {
      type: "text",
      name: "bookName",
      placeholder: "Book Heading",
      label: "Book Name",
      width: "100%",
    },
    {
      type: "textarea",
      name: "description",
      placeholder: "Content",
      label: "Content",
      width: "100%",
    },
  ];
  const [formData, setFormData] = useState({});
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState([]);
  const [appId, setAppId] = useState("");
  const [userTypeId, setUserTypeId] = useState("");
  const [bookImage, setBookImage] = useState("");
  const [removeImage, setRemoveImage] = useState(false);
  const navigate = useNavigate();
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    setFormData({});
    setRemoveImage(isClosed);
    setIsPremium(false);
  }, [isClosed]);

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    const id = generateUUID()
    const fileKey = `book-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      dispatch(isLoading(true));
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        handleAdd(upload.Location);
      }
    } catch (error) {
      console.log(error);
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the resume.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleAdd = (imageUrl) => {
    const languageId = localStorage.getItem("languageId");
    const appId = localStorage.getItem("appId");

    formData["languageId"] = languageId;
    formData["appId"] = appId;
    formData["isPremium"] = isPremium;

    if (imageUrl && !imageUrl?.includes('undefined')) {
      formData['bookImage'] = imageUrl;
    } else {
      delete formData.bookImage
    }



    dispatch(addBook(axiosInstance, formData))
      .then((res) => {
        setToastList([
          {
            id: 0,
            // title: "Book Added",
            title: "New Book Added Successfully",
            icon: "success",
          },
        ]);

        setTimeout(() => {
          setRemoveImage(true);
          setFormData({});
          setIsPremium(false);
          handleClose();
        }, 1500);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Adding Book Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });


  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (bookImage.name) {
      uploadToS3(bookImage, bookImage.name);
    } else {
      handleAdd();
    }
  };

  const handleFile = (url) => {
    if (url) {
      setBookImage(url);
    }
  };

  const handleToggle = () => {
    setIsPremium(!isPremium);
  }

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.heading}>
          <Heading
            title={BOOKS_MANAGEMENT.ADD_BOOK.HEADING.TITLE}
            description={BOOKS_MANAGEMENT.ADD_BOOK.HEADING.DESCRIPTION}
            textAlign={TextAlign.CENTER}
          />
        </div>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              removeImage={removeImage}
              handleFile={handleFile}
              title={BOOKS_MANAGEMENT.ADD_BOOK.UPLOAD.TITLE}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  type={inputField.type}
                  label={inputField.label}
                  value={formData[inputField.name]}
                  placeholder={inputField.placeholder}
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                />
              </div>
            ))}
            <div className={style.input_field} style={{ width: '50%' }}>
              <label className={style.label}>Is Premium</label>
              <ToggleButton preLable={'No'} postLable={'Yes'} isChecked={isPremium} handleToggle={handleToggle} />
            </div>
            <div className={style.action_button}>
              <Button
                text={BOOKS_MANAGEMENT.ADD_BOOK.BUTTONS.TITLE}
                buttonType={`${buttonStyle.button_primary}`}
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default AddBooks;
