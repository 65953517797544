import React from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import CenterModal from '../../../shared/components/modal/centeredModal/centeredModal'
import SelectAppUserType from '../../../pages/admin/userManagement/selectAppUserType/selectAppUserType'
import Heading from '../../../shared/components/heading/heading'
import Button from '../../../shared/components/button/button'
import Input from '../../../shared/components/inputFields/input'
import Table from '../../../shared/components/table/table'
import useAxiosInstance from '../../../hooks/useAxiosInstance'
import { deleteTip, fetchTipsListing } from '../../../redux/FengShui/manageTips/manageTips.actions'
import { TIPS_MANAGEMENT } from './TipsManagementConstant'
import Delete from '../../../shared/components/delete/delete'
import { isLoading } from '../../../redux/progressLoader/progressLoader.actions'
import { TIPS } from '../../../pages/admin/FengShui/Tips/tipsManagementConstant'
import { selectAdminCategoryListingData } from '../../../redux/FengShui/adminCategory/adminCategory.selectors'
import { fetchAdminCategoryListing } from '../../../redux/FengShui/adminCategory/adminCategory.actions'
import { languageState } from '../../../redux/AurLaVieAdmin/languages/getLanguages.selector'
import { MdOutlineTipsAndUpdates } from 'react-icons/md'
import Toaster from '../../../components/Global/Toaster/Toaster.component'

const TipsManagement = () => {
    const [selectedcategoryValue, setSelectedcategoryValue] = useState({})
    const [categoryOptions, setCategoryOptions] = useState([])
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [isModalClosed, setIsModalClosed] = useState(false)

    const [tips, setTips] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [toastList, setToastList] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [totalItem, setTotalItem] = useState(0);
    const [ids, setIds] = useState([])
    const [showDelete, setShowDelete] = useState({ value: false, id: '' })
    const languageData = useSelector(languageState);
    const state = useSelector(state => state)


    useEffect(() => {
      if(languageData?.languageId) {
        fetchCategoryWithTips()
      }
    }, [languageData, page])

    const fetchCategoryWithTips = () => {
      const params = {
        languageId: languageData?.languageId,
        page,
        limit: pageSize,
        keyword: '',
        categoryId: selectedcategoryValue?.value === "All" ? '' : selectedcategoryValue?.value,
        isAdmin: true,
      }
      fetchCategoryInsideGetTips(params)
    }

    const fetchCategoryInsideGetTips = (getParams) => {
      const params = {
        "languageId": languageData?.languageId,
        page: 1,
        limit: 100000
      };
      dispatch(fetchAdminCategoryListing(axiosInstance, {}, params)).then(res => {
        dispatch(isLoading(true))
        const option = res?.data?.data?.listingData?.map(value => {
          return {
              value: value?._id,
              label: value?.categoryName
          }
        })
        const optionLists = [{
          value: "All",
          label: "All"
        }, ...option]
        setCategoryOptions(optionLists)
        //setSelectedcategoryValue(optionLists?.[0])
        getTips(axiosInstance, {}, getParams)
      }).catch(error => {
          setToastList([{
              id: 0,
              // title: `Error Fetching Category List.`,
              title: error.response.data.message,
              icon: "error",
          }])
      })
    }



    // const fetchCategory = () => {
    //   const params = {
    //     "languageId": languageData?.languageId,
    //     page: 1,
    //     limit: 100000
    //   };
    //   dispatch(fetchAdminCategoryListing(axiosInstance, {}, params)).then(res => {
    //     dispatch(isLoading(true))
    //     const option = res?.data?.data?.listingData?.map(value => {
    //       return {
    //           value: value?._id,
    //           label: value?.categoryName
    //       }
    //     })
    //     const optionLists = [{
    //       value: "All",
    //       label: "All"
    //     }, ...option]
    //     setCategoryOptions(optionLists)
    //     setSelectedcategoryValue(optionLists?.[0])
    //   }).catch(error => {
    //       setToastList([{
    //           id: 0,
    //           // title: `Error Fetching Category List.`,
    //           title: error.response.data.message,
    //           icon: "error",
    //       }])
    //   })
    // }
  
    useEffect(() => {
      if(selectedcategoryValue?.value) {
        setPage(1);
        const params = {
          languageId: languageData?.languageId,
          page,
          limit: pageSize,
          keyword: '',
          categoryId: selectedcategoryValue?.value === "All" ? '' : selectedcategoryValue?.value,
          isAdmin: true,
        }
        getTips(axiosInstance, {}, params);
      }
    }, [selectedcategoryValue?.value])
  
    useEffect(() => {
      setPage(1);
      let timerId;
      const fetchResults = async () => {
        try {
          const params = {
            languageId: state?.getLanguages?.languageId,
            page: 1,
            limit: pageSize,
            isAdmin: true,
            keyword: searchTerm,
            categoryId: selectedcategoryValue?.value === "All" ? '' : selectedcategoryValue?.value,
          }
          getTips(axiosInstance, {}, params);
        } catch (error) {
          console.log(error);
        }
      };
  
      if (searchTerm || searchTerm === "") {
        timerId = setTimeout(() => {
          fetchResults();
        }, 500);
      }
  
      return () => {
        clearTimeout(timerId);
      };
    }, [searchTerm]);
    
    const getTips = (axiosInstance, payload, params) => {
      dispatch(fetchTipsListing(axiosInstance, payload, params))
        .then((response) => {
          const updatedTips = response.data.data.listingData.map((tip, index) => {
            return {
              id: tip._id,
              "sNo": index + 1,
              image: tip?.imgPath ? tip?.imgPath : '/icons/user_blue.svg',
              tipName: tip?.title,
              categoryName: tip?.categoryId?.categoryName,
              createdDate: moment(tip?.created_at).format("MM / DD / YYYY"),
              status: tip?.status
            }
          })
          setTips(updatedTips)
          setTotalItem(response?.data?.data?.meta?.total)
        })
        .catch((err) => {
          setToastList([
            {
              id: 0,
              // title: "Fething Tips Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    };
  
    const onCategoryChange = (event) => {
      setPage(1)
      setSelectedcategoryValue(event);
    };
  
    const handlePage = (page) => {
      setPage(page);
    };
  
    const onSearch = (event) => {
      setSearchTerm(event);
    };
  
    const onEdit = (e) => navigate(`/fengshui/tipsManagement/tips/editTips/${e.id}`, { state: { id: e.id } })

    const onDeleteTip = (type) => {
        if(type === 'Cancel') {
            setShowDelete({ value: false, id: '' })
        } else if (type === 'Ok') {
          if(showDelete.id) {
            deleteItem([showDelete.id])
          } else {
            deleteItem(ids)
          }
        }
    }
  
    const onDelete = item => setShowDelete({ value: true, id: item.id })


    const deleteItem = (idList) => {
      dispatch(deleteTip(axiosInstance, {ids: idList}, {})).then(res => {
          setShowDelete({ value: false, id: '' })
          const params = {
            languageId: languageData?.languageId,
            page,
            limit: pageSize,
            keyword: searchTerm || '',
            categoryId: selectedcategoryValue?.value === "All" ? '' : selectedcategoryValue?.value,
            isAdmin: true,
          }
          getTips(axiosInstance, {}, params);
      }).catch(error => {
          setShowDelete({ value: false, id: '' })
          setToastList([{
              id: 0,
              // title: `Error deleting Tip${idList.length >= 2 ? 's': '' }`,
              title: error.response.data.message,
              icon: "error",
          }])
      })
    }
  
    const onView = (e) => navigate(`/fengshui/tipsManagement/tips/viewTips/${e.id}`)
  
    const addTips = () => navigate(`/fengshui/tipsManagement/tips/addTips`)

    const closeModal = () => {
        setShowAddUserModal(false)
        setIsModalClosed(true)
    }

    
    const handleMultiple = (event) => setIds(event)

    return (!state?.loader?.loading ? 
        <div className="wrapper">
        <CenterModal isOpen={showAddUserModal} onClose={closeModal}>
          <SelectAppUserType isModalClosed={isModalClosed} />
        </CenterModal>
        <CenterModal
                    isOpen={showDelete?.value}
                    onClose={() => {
                      setShowDelete({ value: false, id: '' })
                    }}
                    minWidth={"300px"}
                    >
                    <Delete
                        title={"Delete Tip"}
                        description={"Are you sure you want to delete this tip?"}
                        onButtonClick={onDeleteTip}
                    />
        </CenterModal>
        <section className="title_filters">
          <Heading
            title={TIPS_MANAGEMENT.HEADING.TITLE}
            description={TIPS_MANAGEMENT.HEADING.DESCRIPTION}
            FirstIcon={MdOutlineTipsAndUpdates}
            firstIconSize={60}
            firstIconColor="#0E4D92"
          />
          <div className="filters">
            <Button
              icon={"/icons/add.svg"}
              text={"Add Tip"}
              onClick={addTips}
            />
            <Input
              type={"dropdown"}
              placeholder="All"
              // placeholder={TIPS_MANAGEMENT.FILTERS.APP_TYPE.PLACEHOLDER}
              value={selectedcategoryValue}
              options={categoryOptions}
              width={"180px"}
              onChange={onCategoryChange}
            />
            <Input
              type={"search"}
              placeholder={TIPS_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              onChange={onSearch}
            />
            {ids?.length >= 1 && <Button icon={"/icons/delete.svg"} onClick={() => setShowDelete({ value: true, id: '' })} />}
          </div>
        </section>
        <section className="listing">
          <Table
            data={tips}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            noDataText={TIPS.VIEW_TIPS.TIPS_LIST.NOT_FOUND}
            handleMultiple={(handleMultiple)}
          />
        </section>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
          />
      </div> : null
    ) 
}

export default TipsManagement





















