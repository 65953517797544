import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Editor from '../../../../shared/components/editor/editor';
import Heading from '../../../../shared/components/heading/heading';
import { SETTINGS_MANAGEMENT } from '../settings.constant';
import style from './about.module.scss';
import Toaster from '../../../../components/Global/Toaster/Toaster.component';
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { languageState } from '../../../../redux/AurLaVieAdmin/languages/getLanguages.selector';
import { updateContent, viewContent } from '../../../../redux/AurLaVieAdmin/manageContent/manageContent.actions';
import AWS from "aws-sdk";
import { isLoading } from '../../../../redux/progressLoader/progressLoader.actions';
import { useLocation } from 'react-router-dom';
import { generateUUID } from '../../../../hooks/uniqueIdGenerator';

const About = () => {
    const location = useLocation()
    const isFengShuiSetting = location.pathname === SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI

    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [edit, setEdit] = useState(false);
    const [content, setContent] = useState("");
    const [toastList, setToastList] = useState([]);
    const [contentId, setContentId] = useState(null);
    const languageData = useSelector(languageState);
    // const [appId, setAppId] = useState('');
    const [userImage, setUserImage] = useState("");
    const [aboutImage, setAboutImage] = useState("");
    const [formData, setFormData] = useState({})
    const [subImages1, setSubImages1] = useState("")
    const [subImages2, setSubImages2] = useState("")
    const [subImages3, setSubImages3] = useState("")

    useEffect(() => {
        if (languageData?.languageId) {
            handleViewContent();
        }
    }, [axiosInstance, languageData, isFengShuiSetting]);

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const myBucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
        region: process.env.REACT_APP_AWS_REGION,
    });

    const uploadToS3 = async (file, name) => {
        const id = generateUUID();
        const fileKey = `about-${id}.${file?.type.split("/")[1]}`;
        const params = {
            Body: file,
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: fileKey,
        };
        try {
            dispatch(isLoading(true))
            const upload = await myBucket.upload(params).promise();
            if (upload) {
                dispatch(isLoading(false));
                // editAbout(upload.Location);
                return upload.Location
            }
        } catch (error) {
            setToastList([
                {
                    id: 0,
                    // title: "Error",
                    title: "Error while uploading the resume.",
                    icon: "error",
                },
            ]);
            dispatch(isLoading(false));
        }
    };

    const handleFile = (url) => {
        if (url) {
            setUserImage(url);
        }
    };

    const handleViewContent = () => {
        setEdit(false);
        
        const appId = localStorage.getItem("appId")
        const languageId = languageData?.languageId;
        const pageName = isFengShuiSetting ? "aursome" : "about";
        const params = {
            appId,
            languageId,
            pageName,
        };
        dispatch(viewContent(axiosInstance, params))
            .then((response) => {
                console.log(response)
                setContentId(response?._id);
                setContent(response?.description);
                setAboutImage(response?.images?.[0]);
                setFormData({ subImages: [ response?.images?.[1] || '', response?.images?.[2] || '', response?.images?.[3] || '' ] })
            })
            .catch((error) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Error fetching content ",
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
    };

    const onEdit = () => {
        setEdit(true);
    };

    const handleEdit = () => {
        const fun = async () => {
            let img = ''
            let img1 = ''
            let img2 = ''
            let img3 = ''
            if(userImage?.name) img = await uploadToS3(userImage, userImage?.name)
            if(subImages1?.name) img1 = await uploadToS3(subImages1, subImages1?.name)
            if(subImages2?.name) img2 = await uploadToS3(subImages2, subImages2?.name)
            if(subImages3?.name) img3 = await uploadToS3(subImages3, subImages3?.name)
            editAbout(img, img1, img2, img3)
          }
          fun()
    };

    const editAbout = (imageUrl, img1, img2, img3) => {
        let payload = {
            description: content,
            images: []
        };
        if (imageUrl && !imageUrl?.includes('undefined')) {
            payload['images'].splice(0, 0, imageUrl)
          } else {
            payload['images'].splice(0, 0, userImage === " " ? '' : userImage || aboutImage)
          }
        if(isFengShuiSetting) {
            if (img1 && !img1?.includes('undefined')) {
                payload['images'].splice(1, 0, img1)
              } else {
                payload['images'].splice(1, 0, subImages1 === " " ? '' : subImages1 || formData?.subImages?.[0])
              }
          
              if (img2 && !img2?.includes('undefined')) {
                payload['images'].splice(2, 0, img2)
              } else {
                payload['images'].splice(2, 0, subImages2 === " " ? '' : subImages2 || formData?.subImages?.[1])
              }
          
              if (img3 && !img3?.includes('undefined')) {
                payload['images'].splice(3, 0, img3)
              } else {
                payload['images'].splice(3, 0, subImages3 === " " ? '' : subImages3 || formData?.subImages?.[2])
              }
        }
        const params = {
            id: contentId,
        };
        dispatch(updateContent(axiosInstance, payload, params))
            .then((response) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Content Updated",
                        title: response.message,
                        icon: "success",
                    },
                ]);
                setEdit(false)
                // setTimeout(() => {
                //     handleViewContent();
                // }, 1000);
            })
            .catch((error) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Error fetching content ",
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
    };

    const handleContent = (event) => {
        setContent(event);
    };

    const handleSubFile1 = (url) => {
        if (url || url === "") {
          setSubImages1(url)
        }
      }
      const handleSubFile2 = (url) => {
        if (url || url === "") {
          setSubImages2(url)
        }
      }
      const handleSubFile3 = (url) => {
        if (url || url === "") {
          setSubImages3(url)
        }
      }

    return (
        <section className={style.container}>
            <div className={style.wrapper}>
                <section className={style.title_filters}>
                    <Heading
                        title={isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.HEADING.TITLE : SETTINGS_MANAGEMENT.ABOUT.HEADING.TITLE}
                        description={
                            isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.HEADING.DESCRIPTION :
                                SETTINGS_MANAGEMENT.ABOUT.HEADING.DESCRIPTION
                        }
                        fontSize={'30px'}
                    />
                    <div className={style.filters}>
                        {edit ? (
                            <Button
                                text={
                                    isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.BUTTONS.SAVE_TITLE :
                                        SETTINGS_MANAGEMENT.ABOUT.BUTTONS.SAVE_TITLE}
                                buttonType={`${buttonStyle.button_primary}`}
                                onClick={handleEdit}
                            />
                        ) : (
                            <Button
                                icon={"/icons/edit.svg"}
                                text={isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.BUTTONS.TITLE :
                                    SETTINGS_MANAGEMENT.ABOUT.BUTTONS.TITLE}
                                onClick={onEdit}
                            />
                        )}
                    </div>
                </section>
                <section className={style.content}>
                    <div className={style.upload}>
                        <h3 className={style.heading}>Image</h3>
                        <ImageUpload isDisabled={!edit} handleFile={handleFile} previewImage={aboutImage} id={'mainimage1'} />
                        {/* <div className={style.inner_uploads}>
                            <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} id={'upload1'} />
                            <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} id={'upload2'} /><ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'8px'} id={'upload3'} />
                        </div> */}
                        {isFengShuiSetting  && 
                        <div className={style.smallUpload}>
                            <ImageUpload isDisabled={!edit} height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} handleFile={handleSubFile1} id={'mainimage2'} previewImage={formData?.subImages?.[0]} />
                            <ImageUpload isDisabled={!edit} height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} handleFile={handleSubFile2} id={'mainimage3'} previewImage={formData?.subImages?.[1]} />
                            <ImageUpload isDisabled={!edit} height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} handleFile={handleSubFile3} id={'mainimage4'} previewImage={formData?.subImages?.[2]} />
                        </div>}
                    </div>
                    <div className={style.inner_content}>
                        {edit ? (
                            <h3 className={style.heading}>Edit Content</h3>
                        ) : (
                            <h3 className={style.heading}>Content</h3>
                        )}

                        {edit ? (
                            <Editor content={content} setContent={handleContent} />
                        ) : (
                            <div className={style.container} dangerouslySetInnerHTML={{ __html: content }}></div>
                            // <Editor content={content} readyOnly={true} />
                        )}
                    </div>
                </section>
            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </section>
    )
}

export default About;