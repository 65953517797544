import AppSwitchButton from "../../../../shared/components/appSwitchButton/appSwitchButton";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import { USER_MANAGEMENT } from "../userManagementConstants";
import style from "./viewUser.module.scss";
import { useEffect, useRef, useState } from "react";
import {
  deleteAppUser,
  getBetaUserPropertyList,
  getbetaUserResidentList,
  viewUser,
} from "../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { extractSubstring } from "../../../../hooks/useExtractSubstring";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import BetaUserDetails from "../../../../components/FengShui/BetaUserDetails/BetaUserDetails";
import { Country, State } from "country-state-city";
import LinkModal from "../../../../components/FengShui/LinkModal/LinkModal";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import { loaderActions } from "../../../../redux/progressLoader/progressLoader.reducer";
import { setLoading } from "../../../../redux/anotherLoading/anotherLoading";
import ProgressLoaderComponent from "../../../../components/Global/ProgressLoader/ProgressLoader.component";

const ViewUser = () => {
  const location = useLocation();
  const [toastList, setToastList] = useState([]);
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const fullUserData = useRef();
  const navigate = useNavigate();
  const [propertyProfileDetails, setPropertyProfileDetails] = useState([]);
  const [propertyProfileDetailsraw, setPropertyProfileDetailsraw] = useState(
    []
  );
  const [residentProfileDetails, setResidentProfileDetails] = useState([]);
  const [residentProfileDetailsraw, setResidentProfileDetailsraw] = useState(
    []
  );
  const [isFengshueBetaUser, setIsFengshueBetaUser] = useState(false);
  const state = useSelector((state) => state);
  console.log(state?.anotherLoading?.anotherLoading, "statestatestate");

  useEffect(() => {
    const queryParams = {
      isAdmin: true,
    };
    dispatch(setLoading(true));
    viewUserDetails(axiosInstance, location?.state?.email, queryParams);
  }, []);

  const handleSwitch = (data) => {
    setUserDetails(
      fullUserData.current.data?.[0].appId.appName === data
        ? fullUserData.current.data?.[0]
        : fullUserData.current.data?.[1]
    );
    if (data === "Feng-Shui") {
      // const id = fullUserData?.current?.data?.filter(item => item.name === 'FengShui')?.[0]?._id
      const id = fullUserData?.current?.data?.filter(
        (item) => item.appId.appName === "Feng-Shui"
      )?.[0]?._id;
      viewUserPropertyDetails(id);
      viewUserResidentDetails(id);
    }
    // setIsFengshueBetaUser(data === "Feng-Shui")
  };

  function viewUserDetails(axiosInstance, userId, params) {
    dispatch(viewUser(axiosInstance, userId, params))
      .then((response) => {
        if (response.length === 2) {
          fullUserData.current = {
            length: 2,
            data: response,
          };
        } else {
          fullUserData.current = {
            length: 1,
            data: response?.[0],
          };
        }
        setUserDetails(response?.[0]);
        setIsFengshueBetaUser(
          response?.[0]?.appId?.appName === "Feng-Shui" &&
            response?.[0]?.userTypeId?.typeName === "Beta"
        );
        if (
          response?.[0]?.appId?.appName === "Feng-Shui" &&
          response?.[0]?.userTypeId?.typeName === "Beta"
        ) {
          viewUserPropertyDetails(response?.[0]?._id);
          viewUserResidentDetails(response?.[0]?._id);
        } else {
          // dispatch(isLoading(false))
        }
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }

  const viewUserPropertyDetails = (userId) => {
    const params = {
      userId,
      // limit: ,
      page: 1,
    };

    dispatch(getBetaUserPropertyList(axiosInstance, {}, params))
      .then((response) => {
        const data = response?.data?.data?.map((value) => ({
          id: value._id,
          image: value.imgPath ? value?.imgPath : "/icons/user_blue.svg",
          PropertyName: value?.propName,
          PropertyType: value?.propTypeId.typeName,
          Address: `${value?.streetNumber || ""} ${value?.streetName || ""} ${
            value?.cityId || ""
          } ${
            State.getStateByCodeAndCountry(value?.stateId, value?.countryId)
              ?.name || ""
          } ${Country.getCountryByCode(value?.countryId)?.name || ""} ${
            value?.zipCode || ""
          }`,
          LastEditedOn: moment(value?.modified_at).format("MM/DD/YYYY"),
        }));
        setPropertyProfileDetailsraw(response?.data?.data);
        setPropertyProfileDetails(data);
      })
      .catch((error) => {
        console.log(error);
        setToastList([
          {
            id: 0,
            title: error.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const viewUserResidentDetails = (userId) => {
    const query = {
      userId,
      page: 1,
    };
    dispatch(setLoading(true));
    dispatch(getbetaUserResidentList(axiosInstance, {}, {}, query))
      .then((response) => {
        const data = response?.data?.data?.map((value) => ({
          id: value.userProfile._id,
          image: value.userProfile?.profilePic
            ? value?.userProfile?.profilePic
            : "/icons/user_blue.svg",
          ResidentName: value?.userProfile.name,
          PropertyName: getString(value?.propertyDetails),
          Gender: value?.userProfile.gender,
          CreatedDate: moment(value?.userProfile.created_at).format(
            "MM/DD/YYYY"
          ),
        }));
        let rawData = response?.data?.data.map((data) => {
          return {
            ...data.userProfile,
            userPropertyName: getString(data?.propertyDetails),
            propertyDetail: data?.propertyDetails,
          };
        });
        setResidentProfileDetailsraw(rawData);
        setResidentProfileDetails(data);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error Fetching User Property Details",
            title: error.response?.data?.message,
            icon: "error",
          },
        ]);
      })
      .finally(() => {
        dispatch(setLoading(false));
        //
      });
  };

  const deleteUser = () => {
    setShowDeleteModal(true);
  };

  const editUser = () => {
    navigate(`/userManagement/editUser/${userDetails._id}`, {
      state: { email: userDetails.email },
    });
  };

  const getString = (property) => {
    let arr = [];
    property.map((data) => arr.push(data?.propName));
    return arr.join(" , ");
  };

  const handleDeleteUser = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
    } else {
      const params = {
        isAdmin: true,
      };
      const data = {
        userId: userDetails._id,
        appId: localStorage.getItem("appId"),
      };
      dispatch(deleteAppUser(axiosInstance, data, params))
        .then((res) => {
          setShowDeleteModal(false);
          setToastList([
            {
              id: 0,
              // title: "User Deleted",
              title: "User Deleted Successfully",
              icon: "success",
            },
          ]);
          setTimeout(() => {
            navigate("/userManagement");
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Delete User Error",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  if (
    !state?.getLanguages?.languageId ||
    state?.manageUsers?.data?.length <= 0
  ) {
    dispatch(isLoading(true));
    return null;
  }
  // if(isFengshueBetaUser && state?.manageUsers?.propertyListInBetaUser !== "success") {
  //   return <ProgressLoaderComponent />
  // }
  // state?.manageUsers?.propertyListInBetaUser !== "success"

  const handleProfileSubsData = (profile) => {
    let subsData;
    userDetails.userSubcriptionData.map((subs) => {
      if (profile?._id === subs?.subProfileId) subsData = subs;
    });
    return subsData;
  };

  return !state?.anotherLoading?.anotherLoading ? (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={USER_MANAGEMENT.DELETE.TITLE}
            description={USER_MANAGEMENT.DELETE.DESCRIPTION}
            onButtonClick={handleDeleteUser}
          />
        </CenterModal>
        <CenterModal
          isOpen={showLinkModal}
          onClose={() => {
            setShowLinkModal(false);
          }}
          minWidth={"300px"}
        >
          <LinkModal
            refresh={() => viewUserResidentDetails(userDetails?._id)}
            setShowLinkModal={setShowLinkModal}
            setToastList={setToastList}
            property={propertyProfileDetailsraw}
            resident={residentProfileDetails}
            userId={userDetails?._id}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={USER_MANAGEMENT.VIEW_USER.BACK.TITLE}
            description={USER_MANAGEMENT.VIEW_USER.BACK.DESCRIPTION}
          />
        </div>
        <div className={style.heading}>
          <Heading
            title={extractSubstring(userDetails?._id)}
            description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION}
          />
          {userDetails?.appId?.appName && userDetails?.userTypeId?.typeName && (
            <div className={style.app_user}>
              <img src="/icons/info_blue.svg" alt="info" width={30} />
              <span className={style.app}>
                {`${userDetails?.appId?.appName.replace(/-/g, " ")} App` || ""}
              </span>
              <span className={style.user}>
                {`${userDetails?.userTypeId?.typeName} User` || ""}
              </span>
            </div>
          )}
          <div className={style.action_buttons}>
            <AppSwitchButton
              length={fullUserData?.current?.length}
              handleSwitch={handleSwitch}
              selectedApp={userDetails?.appId?.appName}
            />
            <Button icon={"/icons/edit.svg"} onClick={editUser} />
            <Button icon={"/icons/delete.svg"} onClick={deleteUser} />
          </div>
        </div>
        <div className={style.details}>
          <div className={style.profile_image}>
            <img
              src={
                userDetails?.profilePic
                  ? userDetails?.profilePic
                  : "/icons/user_blue.svg"
              }
              alt="profile"
            />
          </div>
          <div className={style.profile_details}>
            <Heading
              title={USER_MANAGEMENT.VIEW_USER.HEADING.TITLE}
              description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION}
              fontSize={"1.4rem"}
            />
            <hr className={style.divider} />
            <div className={style.column}>
              <div className={style.row}>
                <div className={style.sub_detail}>
                  <label className={style.label}>User Id</label>
                  <p className={style.value}>{userDetails?._id?.slice(0, 5)}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>Name</label>
                  <p className={style.value}>{userDetails?.name}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>Email Id</label>
                  <p className={style.value}>{userDetails?.email}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>Using App</label>
                  <p className={style.value}>{userDetails?.appId?.appName}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>Created On</label>
                  <p className={style.value}>
                    {moment(userDetails?.created_at).format("MM/DD/YYYY")}
                  </p>
                </div>
                {/* <div className={style.sub_detail}>
                  <label className={style.label}>Active User</label>
                  <p
                    className={style.value}
                    style={{ textTransform: "capitalize" }}
                  >
                    {userDetails?.status}
                  </p>
                </div> */}
                {/* {userDetails?.appId?.appName === "Auracle-Life" && (
                  <div className={style.sub_detail}>
                    <label className={style.label}>VIP User</label>
                    <p className={style.value}>
                      {userDetails?.isVip ? "Yes" : "No"}
                    </p>
                  </div>
                )} */}
                {/* {userDetails?.appId?.appName === "Auracle-Life" && (
                  <div className={style.sub_detail}>
                    <label className={style.label}>Premium User</label>
                    <p className={style.value}>
                      {userDetails?.userSubcriptionData.length > 0
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                )} */}
                {/* {userDetails?.appId?.appName === "Auracle-Life" &&
                  userDetails?.userSubcriptionData.length > 0 && (
                    <div className={style.sub_detail}>
                      <label className={style.label}>Premium Expires On</label>
                      <p className={style.value}>
                        {moment(
                          userDetails?.userSubcriptionData[0]?.expiryDate
                        ).format("MM/DD/YYYY")}
                      </p>
                    </div>
                  )} */}
              </div>
            </div>
            {userDetails?.appId?.appName !== "Feng-Shui" &&
              userDetails?.userProfileData?.length > 0 && (
                <div className={style.astrology_details}>
                  <Heading
                    title={"Astrology Profiles"}
                    description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION}
                    fontSize={"1.4rem"}
                  />
                  {userDetails?.userProfileData &&
                    userDetails?.userProfileData.length > 0 &&
                    userDetails?.userProfileData.map((profile, index) => {
                      return (
                        <div key={index}>
                          <hr className={style.divider} />
                          <h4 className={style.sub_heading}>
                            Astrology Profile {index + 1}
                          </h4>
                          <div className={style.astrologers}>
                            <div className={style.profile_image}>
                              <img
                                src={
                                  profile?.profilePic
                                    ? profile?.profilePic
                                    : "/icons/user_blue.svg"
                                }
                                alt="profile"
                              />
                            </div>
                            <div className={style.column}>
                              <div className={style.row}>
                                <div className={style.sub_detail}>
                                  <label className={style.label}>
                                    Full Name
                                  </label>
                                  <p className={style.value}>{profile?.name}</p>
                                </div>
                                <div className={style.sub_detail}>
                                  <label className={style.label}>
                                    Date Of Birth
                                  </label>
                                  <p className={style.value}>
                                    {moment
                                      .utc(profile?.dob)
                                      .format("MM/DD/YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className={style.row}>
                                <div className={style.sub_detail}>
                                  <label className={style.label}>
                                    Birth Time
                                  </label>
                                  <p className={style.value}>
                                    {moment
                                      .utc(profile?.dob)
                                      .local()
                                      .format("hh:mm")}
                                  </p>
                                </div>
                                <div className={style.sub_detail}>
                                  <label className={style.label}>
                                    Are You Twin
                                  </label>
                                  <p className={style.value}>
                                    {profile?.twins ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              <div className={style.row}>
                                <div className={style.sub_detail}>
                                  <label className={style.label}>Gender</label>
                                  <p className={style.value}>
                                    {profile?.gender}
                                  </p>
                                </div>
                                <div className={style.sub_detail}>
                                  <label className={style.label}>
                                    Sexual Preference
                                  </label>
                                  <p className={style.value}>
                                    {profile?.sexualPreference}
                                  </p>
                                </div>
                              </div>
                              {
                                <div className={style.row}>
                                  {handleProfileSubsData(profile)
                                    ?.expiryDate ? (
                                    <div className={style.sub_detail}>
                                      <label className={style.label}>
                                        Premium Expires On
                                      </label>
                                      <p className={style.value}>
                                        {moment(
                                          handleProfileSubsData(profile)
                                            ?.expiryDate
                                        ).format("MM/DD/YYYY")}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className={style.sub_detail} />
                                  )}
                                  <div className={style.sub_detail}>
                                    <div
                                      className={style.input_field}
                                      style={{ width: "50%" }}
                                    >
                                      <label className={style.label}>
                                      Admin
                                      </label>
                                      <p className={style.value}>
                                        {profile?.isEditable ? "Yes" : "No"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
        </div>

        {/* Beta User */}

        {isFengshueBetaUser && (
          <>
            <BetaUserDetails
              DATA={propertyProfileDetails}
              raw={propertyProfileDetailsraw}
              heading="Property Profile Details"
              subHeading="Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex."
              title="Property"
              refresh={() => viewUserPropertyDetails(userDetails?._id)}
              refreshResident={() => viewUserResidentDetails(userDetails?._id)}
              setToastList={setToastList}
              userId={userDetails?._id}
            />
            {propertyProfileDetails.length >= 1 ? (
              <>
                <BetaUserDetails
                  DATA={residentProfileDetails}
                  raw={residentProfileDetailsraw}
                  heading="Resident Details"
                  subHeading="Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex."
                  title="Resident"
                  refresh={() => viewUserResidentDetails(userDetails?._id)}
                  setToastList={setToastList}
                  userId={userDetails?._id}
                />
                <div className={style.link_button_container}>
                  <Button
                    disabled={showLinkModal}
                    type="button"
                    onClick={() => setShowLinkModal(true)}
                    text="Link Resident With Property"
                    buttonType={buttonStyle.button_primary}
                  />
                </div>
              </>
            ) : (
              <div style={{ marginBottom: 100 }} />
            )}
          </>
        )}
        {/* Beta User */}
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  ) : (
    <ProgressLoaderComponent />
  );
};

export default ViewUser;
