import './heading.scss';

const Heading = (props) => {

    const { icon, title, fontSize, textAlign, description, subtitle, fontStyle, FirstIcon, firstIconSize, firstIconColor } = props;

    return <div className="heading_container">
        <div className="heading_wrapper">
            {icon !== undefined ? <div className="icon">
                <img src={icon} alt={title} loading="lazy" />
            </div> : ''}
            {FirstIcon && <FirstIcon color={firstIconColor} size={firstIconSize} className="icon"/>}
            <div className="heading" style={{ textAlign: textAlign }}>
                <h1 className="title" style={{ fontSize: fontSize, fontStyle: fontStyle }}>
                    {title}<span className='subtitle'>{subtitle}</span></h1>
                <p className="description">{description}</p>
            </div>
        </div>
    </div>
}

export default Heading;