import Back from "../../../../../shared/components/backButton/backButton";
import Button from "../../../../../shared/components/button/button";
import { BOOKS_MANAGEMENT } from "../../booksManagementConstants";
import style from "./addBookSection.module.scss";
import buttonStyle from "../../.././../../shared/components/button/button.module.scss";
import Input from "../../../../../shared/components/inputFields/input";
import Editor from "../../../../../shared/components/editor/editor";
import { useEffect, useState } from "react";
import Chip from "../../../../../shared/components/chips/chips";
import { addBookSection } from "../../../../../redux/AurLaVieAdmin/books/books.action";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { axiosInstance } from "../../../../../constants/axiosInstance";

const AddBookSection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [description, setDescription] = useState("");
  const [keywordsHeading, setKeywordsHeading] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [toastList, setToastList] = useState([]);
  const { id } = location.state;
  const [formData, setFormData] = useState({});

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value;
      setTags([...tags, { label: newTag }]);
      event.target.value = "";
      setFormData({});
    }
  };

  const handleInputBlur = () => {
    let errors = {};
    if (!sectionName.trim().length) {
      errors = { ...errors, sectionName: "Section Name is required" };
    } else {
      delete errors.sectionName;
    }

    // if (!tags.length) {
    //   errors = { ...errors, tags: "Tags are required" };
    // } else {
    //   delete errors.tags;
    // }

    // if (!keywordsHeading.trim().length) {
    //   errors = { ...errors, keywordsHeading: "Keyword Heading is required" };
    // } else {
    //   delete errors.keywordsHeading;
    // }

    if (!description.trim().length) {
      errors = { ...errors, description: "Description is required" };
    } else {
      delete errors.description;
    }

    if (Object.keys(errors).length) {
      return setFormErrors({ ...errors });
    } else {
      setFormErrors({});
      const languageId = localStorage.getItem("languageId");
      const appId = localStorage.getItem("appId");
      const updatedDescription = description.replaceAll("<br>", "<br/>");
      const newArray = tags.map((element) => {
        return element.label;
      });

      const payload = {
        sectionName,
        keywordsHeading,
        bookId: id,
        languageId,
        appId,
        keywords: newArray,
        description: updatedDescription,
      };
      dispatch(addBookSection(axiosInstance, payload))
        .then((res) => {
          setToastList([
            {
              id: 0,
              // title: "Section Added successfully",
              title: res?.message,
              icon: "success",
            },
          ]);

          setTimeout(() => {
            navigate(`/aurlavie/booksManagement/editBook/${id}`);
          }, 700);
        })
        .catch((err) => {
          console.log(err);

          setToastList([
            {
              id: 0,
              // title: "Adding section Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const remove = (chipToBeRemoved) => {
    const newTags = tags.filter((tag) => tag.label !== chipToBeRemoved.label);
    setTags(newTags);
  };

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={BOOKS_MANAGEMENT.ADD_BOOK_SECTION.BACK.TITLE}
            description={BOOKS_MANAGEMENT.ADD_BOOK_SECTION.BACK.DESCRIPTION}
          />
        </div>
        <form>
          <div className={style.input_field} style={{ width: "100%" }}>
            <div className={style.tags}>
              <Input
                type="text"
                name="sectionName"
                placeholder="Section Name"
                label="Section Name"
                value={sectionName}
                onChange={(data) => setSectionName(data)}
              />
              <div className={style.error}>{formErrors["sectionName"]}</div>
            </div>
          </div>
          <div className={style.input_field} style={{ width: "50%" }}>
            <div className={style.tags}>
              <Input
                type="text"
                name="tags"
                placeholder="Add tag and press enter"
                label="Tags"
                value={formData["tags"]}
                onChange={(value) => handleInputChange("tags", value)}
                onKeyDown={handleKeyDown}
              />
              {/* <img src="/icons/add.svg" alt="add" className={style.add} /> */}
              <div className={style.error}>{formErrors["tags"]}</div>
            </div>
          </div>
          <div className={style.input_field} style={{ width: "50%" }}>
            <Input
              type="text"
              name="keywordsHeading"
              placeholder="Tag Heading"
              label="Tag Heading"
              width="50%"
              value={keywordsHeading}
              onChange={(data) => setKeywordsHeading(data)}
            />
            <div className={style.error}>{formErrors["keywordsHeading"]}</div>
          </div>
          <div className={style.tags_container}>
            <Chip showRemove={true} data={tags} onRemove={remove} />
          </div>
          <div className={style.editor}>
            <h4 className={style.label}>Content</h4>
            <Editor
              setContent={(data) => setDescription(data)}
              content={description}
            />
            <div className={style.error}>{formErrors["description"]}</div>
          </div>
        </form>
        <div className={style.action_buttons}>
          <Button
            text={"Add Section"}
            buttonType={`${buttonStyle.button_primary}`}
            marginTop={0}
            onClick={handleInputBlur}
          />
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </section>
  );
};

export default AddBookSection;
