import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth.hook";
import io from 'socket.io-client';
// const socket = io.connect(process.env.REACT_APP_SOCKET_URL)

const useHandleLogout = () => {
    const navigateTo = useNavigate()
    const {getUserAuth, removeUserAuth} = useAuth()

    const handleLogout=()=>{
        // socket.off('connect');
        // socket.off('disconnect');
        // socket.disconnect();
        removeUserAuth()
        navigateTo('/login')
    }

    const handleBrowserCloseLogout=()=>{
        // socket.off('connect');
        // socket.off('disconnect');
        // socket.disconnect();
        removeUserAuth()
    }

    return { handleLogout, handleBrowserCloseLogout};

}

export default useHandleLogout;