import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import Input from "../../../../shared/components/inputFields/input";
import Table from "../../../../shared/components/table/table";
import { BOOKS_MANAGEMENT } from "../booksManagementConstants";
import style from "./editBook.module.scss";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteBook,
  deleteBookSection,
  updateBook,
  viewBook,
} from "../../../../redux/AurLaVieAdmin/books/books.action";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import AWS from "aws-sdk";
import moment from "moment";
import useExtractSubstring from "../../../../hooks/useExtractSubstring";
import ToggleButton from "../../../../shared/components/toggleButton/toggleButton";
import { generateUUID } from "../../../../hooks/uniqueIdGenerator";

const EditBook = () => {
  const inputFields = [
    {
      type: "text",
      name: "bookName",
      placeholder: "Book Name",
      label: "Book Name",
      width: "100%",
    },
    {
      type: "textarea",
      name: "description",
      placeholder: "Content",
      label: "Content",
      width: "100%",
    },
    {
      type: "number",
      name: "bookOrder",
      placeholder: "Book Order",
      label: "Book Order",
      width: "20%",
    },
  ];
  const [formData, setFormData] = useState({});
  const [toastList, setToastList] = useState([]);
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteBook, setShowDeleteBook] = useState(false);
  const navigate = useNavigate();
  const [bookData, setBookData] = useState(null);
  const [bookImage, setBookImage] = useState(null);
  const [bookSection, setBookSection] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [ids, setIds] = useState([]);
  const currentDeleteRef = useRef(null);

  const { id } = useParams();

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  useEffect(() => {
    currentDeleteRef.current = null
    handleViewBook();
  }, [axiosInstance]);

  const handleViewBook = () => {
    dispatch(viewBook(axiosInstance, id))
      .then((res) => {
        setFormData({
          ...formData,
          ["bookName"]: res.bookName,
          ["description"]: res.description,
          ["bookImage"]: res.bookImage ? res.bookImage : "",
          ["bookOrder"]: res.bookOrder || 0,
        });
        setIsPremium(res.isPremium);
        setBookSection(
          [...res?.bookSections].map((element, index) => {
            return {
              id: element._id,
              "S. No":
                index + 1 > 100
                  ? index + 1
                  : index + 1 < 100 && index + 1 > 9
                    ? `0${index + 1}`
                    : `00${index + 1}`,
              "Section Name": element.sectionName,
              "Last Edited On": moment(element?.modified_at).format(
                "MM/DD/YYYY"
              ),
            };
          })
        );
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Fething Book  By Id Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const uploadToS3 = async (file, name) => {
    dispatch(isLoading(true));
    const id = generateUUID();
    const fileKey = `book-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        handleEdit(upload.Location);
      }
    } catch (error) {
      console.log(error);
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while adding the image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleFile = (url) => {
    if (url || url === "") {
      setBookImage(url);
    }
  };

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (bookImage) {
      uploadToS3(bookImage, bookImage.name);
    } else {
      handleEdit();
    }
  };

  const handleEdit = (imageUrl) => {
    const languageId = localStorage.getItem("languageId");
    const appId = localStorage.getItem("appId");

    formData["languageId"] = languageId;
    formData["appId"] = appId;
    formData["isPremium"] = isPremium;

    if (imageUrl) {
      formData["bookImage"] = imageUrl;
    }

    if (bookImage === " ") {
      formData["bookImage"] = "";
    }

    dispatch(updateBook(axiosInstance, formData, id))
      .then((res) => {
        setToastList([
          {
            id: 0,
            // title: "Book Updated",
            title: "Book Updated Successfully",
            icon: "success",
          },
        ]);

        setTimeout(() => {
          navigate("/aurlavie/booksManagement");
        }, 1500);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Adding Book Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const onButtonClick = (clickedButton) => {
    if (clickedButton === "Cancel") {
      setShowDelete(false);
    } else if (clickedButton === "Ok") {
      const data = currentDeleteRef.current?.length
        ? { ids: currentDeleteRef.current }
        : { ids };
      deleteBooksSection(axiosInstance, data);
    }
  };

  const onDelete = (data) => {
    currentDeleteRef.current = [data.id];
    setShowDelete(true);
  };

  const goToBookSectionDetail = (data) => {
    navigate(`/aurlavie/booksManagement/viewBookSection/${data.id}`);
  }

  const onEdit = (data) => {
    navigate(`/aurlavie/booksManagement/editBookSection/${data.id}`);
  }

  const addSection = () => {
    navigate("/aurlavie/booksManagement/addBookSection", {
      state: { id },
    });
  };

  const deleteBooks = (clickedButton) => {
    if (clickedButton === "Cancel") {
      setShowDeleteBook(false);
    } else if (clickedButton === "Ok") {
      dispatch(deleteBook(axiosInstance, { ids: [id] }))
        .then((response) => {
          setShowDeleteBook(false)
          setToastList([
            {
              id: 0,
              // title: "Book Deleted",
              title: "Book Deleted Successfully",
              icon: "success",
            },
          ]);
          setTimeout(() => {
            navigate("/aurlavie/booksManagement");
          }, 1500);
        })
        .catch((err) => {
          setToastList([
            {
              id: 0,
              // title: "Deleting Books Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const deleteBooksSection = (axiosInstance, payload) => {

    dispatch(deleteBookSection(axiosInstance, payload))
      .then((response) => {
        currentDeleteRef.current = null;
        setShowDelete(false)
        setToastList([
          {
            id: 0,
            // title: "Deleted",
            title: "Book Sections deleted successfully",
            icon: "success",
          },
        ]);


        setTimeout(() => {
          handleViewBook()
        }, 1000)

      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Deleting Book Section Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleToggle = () => {
    setIsPremium(!isPremium);
  }

  const handleMultiple = (event) => {
    setIds(event);
  };

  const deleteMany = () => {
    if (ids.length) {
      setShowDelete(true);
    }
  }

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDelete}
          onClose={() => {
            setShowDelete(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={"Delete Section"}
            description={"Are you sure you want to delete this section?"}
            onButtonClick={onButtonClick}
          />
        </CenterModal>
        <CenterModal
          isOpen={showDeleteBook}
          onClose={() => {
            setShowDeleteBook(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={"Delete Book"}
            description={"Are you sure you want to delete this book?"}
            onButtonClick={deleteBooks}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={BOOKS_MANAGEMENT.EDIT_BOOK.BACK.TITLE}
            description={BOOKS_MANAGEMENT.EDIT_BOOK.BACK.DESCRIPTION}
          />
        </div>
        <section className={style.title_filters}>
          <Heading
            title={useExtractSubstring(id)}
            description={BOOKS_MANAGEMENT.EDIT_BOOK.HEADING.DESCRIPTION}
          />
          <div className={style.filters}>
            <Button
              icon={"/icons/add.svg"}
              text={"Add Section"}
              onClick={addSection}
            />
            <Button
              icon={"/icons/delete.svg"}
              text={"Delete Book"}
              onClick={() => {
                setShowDeleteBook(true)
              }}
            />
            <Button
              onClick={handleSubmit}
              text={"Save Changes"}
              buttonType={`${buttonStyle.button_primary}`}
              marginTop={0}
            />
          </div>
        </section>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              handleFile={handleFile}
              previewImage={
                formData["bookImage"] !== "" && formData["bookImage"]
              }
              title={BOOKS_MANAGEMENT.ADD_BOOK.UPLOAD.TITLE}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  type={inputField.type}
                  label={inputField.label}
                  value={formData[inputField.name]}
                  placeholder={inputField.placeholder}
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                />
              </div>
            ))}
            <div className={style.input_field} style={{ width: '80%', textAlign: 'end' }}>
              <label className={style.label}>Is Premium</label>
              <ToggleButton justifyContent={'flex-end'} preLable={'No'} postLable={'Yes'} isChecked={isPremium} handleToggle={handleToggle} />
            </div>
          </form>
        </div>
        <section className={style.listing}>
          <div className={style.heading}>
            <h3 className={style.label}>Sections</h3>
            <Button icon={"/icons/delete.svg"} onClick={deleteMany} />
          </div>
          <Table
            data={bookSection}
            onDelete={onDelete}
            onEdit={onEdit}
            onView={goToBookSectionDetail}
            noDataText={BOOKS_MANAGEMENT.EDIT_BOOK.NO_DATA}
            marginTop={'100px'}
            handleMultiple={handleMultiple}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </section>
  );
};

export default EditBook;