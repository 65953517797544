import React, { useLayoutEffect } from 'react'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

import CenterModal from '../../../shared/components/modal/centeredModal/centeredModal'
import SelectAppUserType from '../../../pages/admin/userManagement/selectAppUserType/selectAppUserType'
import Heading from '../../../shared/components/heading/heading'
import Button from '../../../shared/components/button/button'
import Input from '../../../shared/components/inputFields/input'
import Table from '../../../shared/components/table/table'
import useAxiosInstance from '../../../hooks/useAxiosInstance'
import { CATEGORY } from '../../../pages/admin/FengShui/category/categoryManagementConstant'
import { deleteAdminCategory, fetchAdminCategoryListing } from '../../../redux/FengShui/adminCategory/adminCategory.actions'
import Delete from '../../../shared/components/delete/delete'
import { languageState } from '../../../redux/AurLaVieAdmin/languages/getLanguages.selector'
import { MdOutlineCategory } from 'react-icons/md'
import { isLoading } from '../../../redux/progressLoader/progressLoader.actions'

const CategoryManagement = () => {
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [isModalClosed, setIsModalClosed] = useState(false)

    const [category, setCategory] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [toastList, setToastList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [ids, setIds] = useState([])
    const [showDelete, setShowDelete] = useState({ value: false, id: '' })
    const state = useSelector(state => state)
    const languageData = useSelector(languageState);
    

    useLayoutEffect(() => {
      if(searchTerm === undefined || searchTerm === "") {
        const params = {
          languageId: languageData?.languageId,
          page,
          limit: pageSize,
          keyword: '',
          isAdmin: true,
        }
        dispatch(isLoading(true))
        getCategory(axiosInstance, {}, params);
      } else {
        setPage(1);
        let timerId;
        const fetchResults = async () => {
          try {
            const params = {
              languageId: state?.getLanguages?.languageId,
              page: 1,
              limit: pageSize,
              isAdmin: true,
              keyword: searchTerm,
            }
            getCategory(axiosInstance, {}, params);
          } catch (error) {
            console.log(error);
          }
        };
    
        if (searchTerm || searchTerm === "") {
          timerId = setTimeout(() => {
            fetchResults();
          }, 500);
        }
    
        return () => {
          clearTimeout(timerId);
        };
      }

    }, [page, searchTerm])

    // useEffect(() => {
    // }, [searchTerm]);
  
    const getCategory = (axiosInstance, payload, params) => {
      dispatch(fetchAdminCategoryListing(axiosInstance, payload, params))
        .then((response) => {
          const updatedUsers = response.data.data.listingData.map((value, index) => {
            return {
              id: value?._id,
              "sNo": index + 1,
              image: value?.imgPath ? value?.imgPath : '/icons/user_blue.svg',
              categoryName: value?.categoryName,
              categoryDate: moment(value?.created_at).format("MM / DD / YYYY"),
              status: value?.status
            };
          });
          setCategory(updatedUsers);
          setTotalItem(response?.data?.data?.meta?.total);
        })
        .catch((err) => {
          setToastList([
            {
              id: 0,
              // title: "Fething Users Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  

    const onSearch = (event) => setSearchTerm(event)
    const onDelete = item => setShowDelete({ value: true, id: item.id })
    const onAdd = () => navigate(`/fengshui/tipsManagement/category/addCategory`)
    const onView = (event) => navigate(`/fengshui/tipsManagement/category/viewCategory/${event.id}`)
    const onEdit = (event) => navigate(`/fengshui/tipsManagement/category/editCategory/${event.id}`)

    const closeModal = () => {
        setShowAddUserModal(false)
        setIsModalClosed(true)
    }

    const handlePage = (page) => setPage(page)

    const deleteItem = (idList) => {
      setShowDelete({ value: false, id: '' })
      dispatch(deleteAdminCategory(axiosInstance, {ids: idList}, {})).then(res => {
          const params = {
            "languageId": state?.getLanguages?.languageId,
            page,
            "keyword": searchTerm,
            limit: pageSize,
            isAdmin: true,
          };
          getCategory(axiosInstance, {}, params)
      }).catch(error => {
          setToastList([{
              id: 0,
              // title: `Error deleting category${idList.length >= 2 ? 's': '' }`,
              title: error.response.data.message,
              icon: "error",
          }])
      })
    }

    const onDeleteCategory = (type) => {
      if(type === 'Cancel') {
          setShowDelete({ value: false, id: '' })
      } else if (type === 'Ok') {
        if(showDelete.id) {
          deleteItem([showDelete.id])
        } else {
          deleteItem(ids)
        }
      }
  }

  const handleMultiple = (event) => setIds(event)

    return (!state?.loader?.loading && state?.adminCategory?.listing?.meta ?
        <div className="wrapper">
        <CenterModal isOpen={showAddUserModal} onClose={closeModal}>
          <SelectAppUserType isModalClosed={isModalClosed} />
        </CenterModal>
        <CenterModal
                    isOpen={showDelete.value}
                    onClose={() => {
                      setShowDelete({ value: false, id: '' })
                    }}
                    minWidth={"300px"}
                    >
                    <Delete
                        title={"Delete Category"}
                        description={"Are you sure you want to delete this Category?"}
                        onButtonClick={onDeleteCategory}
                    />
        </CenterModal>
        <section className="title_filters">
          <Heading
            title={CATEGORY.HEADING.TITLE}
            description={CATEGORY.HEADING.DESCRIPTION}
            FirstIcon={MdOutlineCategory}
            firstIconSize={60}
            firstIconColor="#0E4D92"
          />
          <div className="filters">
            <Button
              icon={"/icons/add.svg"}
              text={"Add Category"}
              onClick={onAdd}
            />
            <Input
              type={"search"}
              placeholder={CATEGORY.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              onChange={onSearch}
            />
            {ids?.length >= 1 && <Button icon={"/icons/delete.svg"} onClick={() => setShowDelete({ value: true, id: '' })} />}
          </div>
        </section>
        <section className="listing">
          <Table
            data={category}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            noDataText={'No Category Found'}
            handleMultiple={(handleMultiple)}
          />
        </section>
      </div> : null
    )
}

export default CategoryManagement