import { useEffect, useState } from "react";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../shared/components/inputFields/input";
import { USER_MANAGEMENT } from "../userManagementConstants";
import style from "./editUser.module.scss";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import {
  addSubscription,
  addSubscriptionPayment,
  cancelSubscription,
} from "../../../../redux/AurLaVieAdmin/manageSubscription/manageSubscription.actions";
import AddIcon from "@mui/icons-material/Add";
import {
  Born,
  Gender,
  SexualPreference,
  Status,
  Twins,
} from "../../../../shared/globalConstants/constants";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import {
  addAstrologerProfile,
  deleteAstrologerProfile,
  editUser,
  viewUser,
} from "../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserTypes } from "../../../../redux/AurLaVieAdmin/userType/userType.action";
import ToggleButton from "../../../../shared/components/toggleButton/toggleButton";
import AWS from "aws-sdk";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import dayjs from "dayjs";
import moment from "moment";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import EditAstrologyProfile from "./editAstrologyProfile/editAstrologyProfile";
import { generateUUID } from "../../../../hooks/uniqueIdGenerator";
import AddAstrologyProfile from "./addAstrologyProfile/addAstrologyProfile";

const EditUser = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputFields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      label: "Name",
      required: true,
      width: "50%",
    },
    {
      type: "text",
      name: "email",
      placeholder: "Email ID",
      label: "Email ID",
      required: true,
      width: "50%",
    },
    {
      type: "password",
      name: "password",
      placeholder: "Password",
      label: "Password",
      width: "50%",
    },
    {
      type: "password",
      name: "passwordConfirm",
      placeholder: "Confirm Password",
      label: "Confirm Password",
      width: "50%",
    },
    {
      type: "mui-date-picker",
      name: "dob",
      placeholder: "Date of Birth",
      label: "Date of Birth",
      width: "50%",
    },
    {
      type: "radio",
      name: "gender",
      placeholder: "Gender",
      label: "Gender",
      options: Gender,
      width: "50%",
    },
    {
      type: "radio",
      name: "twins",
      placeholder: "Are you twins?",
      label: "Are you twins?",
      options: Twins,
      width: "50%",
    },
    // {
    //     type: "radio",
    //     name: "born",
    //     placeholder: 'Born',
    //     label: 'Born',
    //     options: Born,
    //     width: '50%'
    // },
    {
      type: "radio",
      name: "sexualPreference",
      placeholder: "Sexual Preference",
      label: "Sexual Preference",
      options: SexualPreference,
      width: "50%",
    },
    // {
    //   type: "radio",
    //   name: "status",
    //   placeholder: "Status",
    //   label: "Status",
    //   options: Status,
    //   width: "50%",
    // },
  ];
  const [formData, setFormData] = useState({});
  const [isVip, setIsVip] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [enableEditMode, setEnableEditMode] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [userSubs, setUserSubs] = useState();
  const [userProfiles, setUserProfiles] = useState();
  const [toastList, setToastList] = useState([]);
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const userId = useParams();
  const location = useLocation();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [userImage, setUserImage] = useState("");
  const [appId, setAppId] = useState("");
  const navigate = useNavigate();
  const [showEditAstrologyModal, setShowEditAstrologyModal] = useState(false);
  const [showAddAstrologyModal, setSAddAstrologyModal] = useState(false);
  const [astrologyProfile, setAstrologyProfile] = useState();

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  useEffect(() => {
    const selectedAppId = localStorage.getItem("appId");
    setAppId(selectedAppId);
  }, [appId]);

  useEffect(() => {
    const queryParams = {
      isAdmin: true,
    };
    viewUserDetails(axiosInstance, location.state.email, queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   useEffect(() => {

  //   }, [userDetails])

  useEffect(() => {
    setUserSubs(userDetails?.userSubcriptionData);
    if (userDetails?.userSubcriptionData?.length > 0) {
      setIsPremium(true);
      setEnableEditMode(false);
    } else {
      setIsPremium(false);
    }
  }, [userDetails?.userSubcriptionData]);

  useEffect(() => {
    if (
      userDetails?.userSubcriptionData[0]?.transactionId === "createdByAdmin" &&
      userDetails?.userSubcriptionData.length > 0
    ) {
      //   setIsPremium(true);
      setEnableEditMode(true);
    } else if (
      userDetails?.userSubcriptionData[0]?.transactionId !== "createdByAdmin" &&
      userDetails?.userSubcriptionData.length > 0
    ) {
      // setIsPremium(true);
      setEnableEditMode(false);
    } else {
      setEnableEditMode(true);
    }
  }, [isPremium, userDetails?.userSubcriptionData]);

  useEffect(() => {
    const hasEmptyRequiredFields = inputFields
      .filter((field) => field.required)
      .some((field) => !formData[field.name]);
    setIsSubmitDisabled(hasEmptyRequiredFields);
  }, [inputFields, formData]);

  const viewUserDetails = (axiosInstance, email, params) => {
    dispatch(viewUser(axiosInstance, email, params))
      .then((details) => {
        let response;

        if (details.length === 2) {
          response = details[0]._id === userId.id ? details[0] : details[1];
        } else {
          response = details[0];
        }
        const res = {
          name: response.name,
          email: response.email,
          dob: response?.dob ? dayjs(response.dob) : null,
          gender: response.gender,
          twins: response.twins,
          born: response.born,
          sexualPreference: response.sexualPreference,
          status: response.status,
          profilePic: response.profilePic,
          appId: response.appId,
          userTypeId: response.userTypeId._id,
          userSubcriptionData: response.userSubcriptionData,
          isEditable: response?.isEditable,
        };

        // setIsVip(response.isVip);
        setUserProfiles(response?.userProfileData);
        setUserDetails(res);
        setFormData(res);
      })
      .catch((error) => {
        console.log("err", error);
        setToastList([
          {
            id: 0,
            // title: "Error fetching user details",
            title: error?.response?.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const editUserDetails = (axiosInstance, userDetails, userId, params) => {
    dispatch(editUser(axiosInstance, userDetails, userId, params))
      .then((response) => {
        if (enableEditMode) {
          if (isPremium) {
            if (userSubs?.length === 0) {
              dispatch(
                addSubscription(axiosInstance, {
                  userId,
                  transactionId: "createdByAdmin",
                  appId: userDetails.appId._id,
                  paymentStatus: "success",
                  subscriptionStatus: "paid",
                  subscriptionType: "Yearly",
                  profileType: "AdminPremium",
                })
              )
                .then((res) => {
                  dispatch(
                    addAstrologerProfile(
                      axiosInstance,
                      {
                        gender: userDetails?.gender,
                        name: userDetails?.name,
                        userId: userId,
                        appId: userDetails?.appId?._id,
                        profilePic: userDetails?.profilePic,
                        born: 0,
                        dob: userDetails?.dob,
                        twins: userDetails?.twins,
                        sexualPreference: userDetails?.sexualPreference,
                        isEditable: userDetails?.isEditable,
                      },
                      params
                    )
                  )
                    .then((response) => {
                      setToastList([
                        {
                          id: 0,
                          title: "User Updated",
                          //   title: response.message,
                          icon: "success",
                        },
                      ]);
                      setTimeout(() => {
                        navigate("/userManagement");
                      }, 1000);
                    })
                    .catch((error) => {
                      console.log("err", error);
                      setToastList([
                        {
                          id: 0,
                          title: "Error fetching user details",
                          //   title: error?.response?.data.message,
                          icon: "error",
                        },
                      ]);
                    });
                })
                .catch((err) => {
                  dispatch(isLoading(false));
                  console.log("err", err?.response?.data?.message);
                });
            } else {
              setToastList([
                {
                  id: 0,
                  title: "User Updated",
                  //   title: response.message,
                  icon: "success",
                },
              ]);
              setTimeout(() => {
                navigate("/userManagement");
              }, 1000);
            }
          } else {
            dispatch(
              cancelSubscription(axiosInstance, {
                subscription_id: userSubs,
              })
            )
              .then((res) => {
                dispatch(deleteAstrologerProfile(axiosInstance, userId))
                  .then((response) => {
                    setToastList([
                      {
                        id: 0,
                        title: "User Updated",
                        // title: response.message,
                        icon: "success",
                      },
                    ]);
                    setTimeout(() => {
                      navigate("/userManagement");
                    }, 1000);
                  })
                  .catch((error) => {
                    setToastList([
                      {
                        id: 0,
                        title: "Error fetching user details",
                        // title: error?.response?.data.message,
                        icon: "error",
                      },
                    ]);
                  });
              })
              .catch((err) => {
                dispatch(isLoading(false));
                console.log("err", err.response.data.message);
              });

            setToastList([
              {
                id: 0,
                // title: "User Updated",
                title: response.message,
                icon: "success",
              },
            ]);
            setTimeout(() => {
              navigate("/userManagement");
            }, 1000);
          }
        } else {
          setToastList([
            {
              id: 0,
              // title: "User Updated",
              title: response.message,
              icon: "success",
            },
          ]);
          setTimeout(() => {
            navigate("/userManagement");
          }, 1000);
        }
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error editing user",
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const getUserType = (axiosInstance, appId, imageUrl) => {
    dispatch(getUserTypes(axiosInstance, appId))
      .then((response) => {
        if (response.length) {
          const userDetails = {
            email: formData.email,
            password: formData.password,
            passwordConfirm: formData.passwordConfirm,
            name: formData.name,
            appId: formData.appId,
            twins: formData.twins,
            gender: formData.gender,
            sexualPreference: formData.sexualPreference,
            // "born": formData.born,
            // isVip: isVip,
            // "isPremium":isPremium,
            dob: formData?.dob,
            status: formData.status,
            userTypeId: formData?.userTypeId,
            profilePic: formData?.profilePic,
          };

          if (imageUrl) {
            userDetails["profilePic"] = imageUrl;
          }

          const params = {
            isAdmin: true,
          };
          editUserDetails(axiosInstance, userDetails, userId.id, params);
        }
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Fetching User Type Failed",
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (userImage.name) {
      uploadToS3(userImage, userImage.name);
    } else {
      getUserType(axiosInstance, appId, userImage);
    }
  };

  const handlePremiumToggle = () => {
    setIsPremium(!isPremium);
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[a-z0-9.]+@[a-z]+\.[a-z]{1,3}$/;
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (name === "name") {
      if (value === "") {
        setFormErrors({ ...formErrors, name: "Name is required" });
      } else if (value.length > 50) {
        setFormErrors({ ...formErrors, name: "Maximum 50 characters allowed" });
      } else if (!nameRegex.test(value)) {
        setFormErrors({ ...formErrors, name: "Only alphabets allowed" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "email") {
      if (value === "") {
        setFormErrors({ ...formErrors, email: "Email is required" });
      } else if (!emailRegex.test(value)) {
        setFormErrors({ ...formErrors, email: "Invalid Email" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "password") {
      if (value !== "" && value.length < 8) {
        setFormErrors({
          ...formErrors,
          password: "Password must be at least 8 characters",
        });
      } else if (value === "") {
        setFormErrors({ ...formErrors, password: "Password is required" });
      } else if (!passwordRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          password:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "passwordConfirm") {
      if (value !== "" && value.length < 8) {
        setFormErrors({
          ...formErrors,
          passwordConfirm: "Password must be at least 8 characters",
        });
      } else if (value === "") {
        setFormErrors({
          ...formErrors,
          passwordConfirm: "Password is required",
        });
      } else if (!passwordRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          passwordConfirm:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    dispatch(isLoading(true));
    const id = generateUUID();
    const fileKey = `user-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        getUserType(axiosInstance, appId, upload.Location);
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleFile = (url) => {
    if (url) {
      setUserImage(url);
    }
  };

  const updateAstrologyProfile = (details) => {
    setShowEditAstrologyModal(true);
    setSAddAstrologyModal(false);
    setAstrologyProfile(details);
  };

  const closeModal = (closedFrom) => {
    if (closedFrom === "modal") {
      const queryParams = {
        isAdmin: true,
      };
      viewUserDetails(axiosInstance, location.state?.email || "", queryParams);
    }
    setShowEditAstrologyModal(false);
    setSAddAstrologyModal(false);
  };

  const handleProfileSubsData = (profile) => {
    let subsData;
    userDetails?.userSubcriptionData.map((subs) => {
      if (profile?._id === subs?.subProfileId) subsData = subs;
    });
    return subsData;
  };

  return (
    <div className={style.container}>
      <CenterModal isOpen={showEditAstrologyModal} onClose={closeModal}>
        <EditAstrologyProfile
          astrologyProfile={astrologyProfile}
          subscriptionData={handleProfileSubsData(astrologyProfile)}
          userId={userId.id}
          closed={closeModal}
        />
      </CenterModal>
      <CenterModal isOpen={showAddAstrologyModal} onClose={closeModal}>
        <AddAstrologyProfile
          // astrologyProfile={astrologyProfile}
          userDetails={userDetails}
          userId={userId.id}
          closed={closeModal}
        />
      </CenterModal>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={USER_MANAGEMENT.EDIT_USER.BACK.TITLE}
            description={USER_MANAGEMENT.EDIT_USER.BACK.DESCRIPTION}
          />
        </div>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              id="edit_user_upload"
              handleFile={handleFile}
              title={USER_MANAGEMENT.EDIT_USER.UPLOAD.TITLE}
              previewImage={userDetails?.profilePic}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <Heading
              title={USER_MANAGEMENT.EDIT_USER.SUB_HEADING.TITLE}
              description={USER_MANAGEMENT.EDIT_USER.SUB_HEADING.DESCRIPTION}
              fontSize={"1.4rem"}
            />
            <hr className={style.divider} />
            <div className={style.form_container}>
              {inputFields.map((inputField, index) => (
                <div
                  key={index}
                  className={style.input_field}
                  style={{ width: inputField.width }}
                >
                  <Input
                    disablePast={false}
                    type={inputField.type}
                    name={inputField.name}
                    label={inputField.label}
                    value={formData[inputField.name]}
                    placeholder={inputField.placeholder}
                    options={inputField.options}
                    required={inputField.required}
                    onChange={(value) =>
                      handleInputChange(inputField.name, value)
                    }
                    handleInputBlur={handleInputBlur}
                    disableFuture={true}
                    views={["year", "day", "hours", "minutes"]}
                    openTo={"year"}
                    ampmRequired={true}
                  />
                  <div className={style.error}>
                    {formErrors[inputField.name]}
                  </div>
                </div>
              ))}
              {/* {userDetails?.appId?.appName === "Auracle-Life" && (
                <div className={style.input_field} style={{ width: "50%" }}>
                  <label className={style.label}>VIP User</label>
                  <ToggleButton
                    preLable={"Inactive"}
                    postLable={"Active"}
                    isChecked={isVip || userDetails?.isVip}
                    handleToggle={handleToggle}
                  />
                </div>
              )} */}
              {/* {userDetails?.appId?.appName === "Auracle-Life" &&
                userDetails?.userSubcriptionData[0]?.expiryDate && (
                  <div className={style.input_field} style={{ width: "50%" }}>
                    <label className={style.label_one}>Expiry Date</label>
                    <label className={style.label} style={{ margin: "10px" }}>
                      {moment(
                        userDetails?.userSubcriptionData[0]?.expiryDate
                      ).format("MM/DD/YYYY")}
                    </label>
                  </div>
                )} */}
              <div className={style.action_button}>
                <Button
                  disabled={
                    isSubmitDisabled ||
                    Object.values(formErrors).some((error) => Boolean(error))
                  }
                  text={USER_MANAGEMENT.EDIT_USER.BUTTONS.TITLE}
                  buttonType={
                    isSubmitDisabled ||
                    Object.values(formErrors).some((error) => Boolean(error))
                      ? `${buttonStyle.button_disabled}`
                      : `${buttonStyle.button_primary}`
                  }
                />
              </div>
              {userDetails?.appId?.appName === "Auracle-Life" && (
                <div className={style.astrology_profiles}>
                  <div className={style.astrology_profiles_wrapper}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "16px",
                      }}
                    >
                      <Heading
                        title={USER_MANAGEMENT.EDIT_USER.PROFILE_DETAILS.TITLE}
                        description={
                          USER_MANAGEMENT.EDIT_USER.PROFILE_DETAILS.DESCRIPTION
                        }
                        fontSize={"1.4rem"}
                      />
                      <AddIcon
                        onClick={() => {
                          userProfiles.length < 5
                            ? setSAddAstrologyModal(true)
                            : setToastList([
                                {
                                  id: 0,
                                  title: "Cannot add more than 5 profiles.",
                                  icon: "error",
                                },
                              ]);
                        }}
                        style={{ borderRadius: "100px", cursor: "pointer" }}
                      />
                    </div>
                    {userProfiles &&
                      userProfiles.length > 0 &&
                      userProfiles.map((profile, index) => {
                        return (
                          <div key={index}>
                            <hr className={style.divider} />
                            <div className={style.action}>
                              <h3 className={style.label}>
                                Astrology Profile {index + 1}
                              </h3>
                              <img
                                src="/icons/edit.svg"
                                alt="edit"
                                onClick={() => {
                                  updateAstrologyProfile(profile);
                                }}
                              />
                            </div>
                            <div className={style.astrologers}>
                              <div className={style.profile_image}>
                                <img
                                  src={
                                    profile?.profilePic
                                      ? profile?.profilePic
                                      : "/icons/user_blue.svg"
                                  }
                                  alt="profile"
                                />
                              </div>
                              <div className={style.column}>
                                <div className={style.row}>
                                  <div className={style.sub_detail}>
                                    <label className={style.label}>
                                      Full Name
                                    </label>
                                    <p className={style.value}>
                                      {profile?.name}
                                    </p>
                                  </div>
                                  <div className={style.sub_detail}>
                                    <label className={style.label}>
                                      Date Of Birth
                                    </label>
                                    <p className={style.value}>
                                      {moment
                                        .utc(profile?.dob)
                                        .format("MM/DD/YYYY")}
                                    </p>
                                  </div>
                                </div>
                                <div className={style.row}>
                                  <div className={style.sub_detail}>
                                    <label className={style.label}>
                                      Birth Time
                                    </label>
                                    <p className={style.value}>
                                      {moment
                                        .utc(profile?.dob)
                                        .local()
                                        .format("hh:mm")}
                                    </p>
                                  </div>
                                  <div className={style.sub_detail}>
                                    <label className={style.label}>
                                      Are You Twin
                                    </label>
                                    <p className={style.value}>
                                      {profile?.twins ? "Yes" : "No"}
                                    </p>
                                  </div>
                                </div>
                                <div className={style.row}>
                                  <div className={style.sub_detail}>
                                    <label className={style.label}>
                                      Gender
                                    </label>
                                    <p className={style.value}>
                                      {profile?.gender}
                                    </p>
                                  </div>
                                  <div className={style.sub_detail}>
                                    <label className={style.label}>
                                      Sexual Preference
                                    </label>
                                    <p className={style.value}>
                                      {profile?.sexualPreference}
                                    </p>
                                  </div>
                                </div>
                                {
                                  <div className={style.row}>
                                    {handleProfileSubsData(profile)
                                      ?.expiryDate ? (
                                      <div className={style.sub_detail}>
                                        <label className={style.label}>
                                          Premium Expires On
                                        </label>
                                        <p className={style.value}>
                                          {moment(
                                            handleProfileSubsData(profile)
                                              ?.expiryDate
                                          ).format("MM/DD/YYYY")}
                                        </p>
                                      </div>
                                    ) : (
                                      <div className={style.sub_detail} />
                                    )}
                                    <div className={style.sub_detail}>
                                      <label className={style.label}>
                                      Admin
                                      </label>
                                      <p className={style.value}>
                                        {profile?.isEditable ? "Yes" : "No"}
                                      </p>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default EditUser;